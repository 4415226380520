import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { FormControl, InputLabel, Input, TextField } from "@material-ui/core";
import { Check, Clear } from "@material-ui/icons";
import PropTypes from "prop-types";
import classNames from "classnames";
import customInputStyle from "../../assets/jss/material-dashboard-pro-react/components/customInputStyle";

const CustomPhoneNumberInput = React.forwardRef((props, ref) =>{
const { 
    classes,
    id,
    type,        
    value,       
    readOnly,     
    placeholder,  
    markers,     
    presence,    
    compareValue,
    onFocus,     
    onBlur,
    onChange,
  } = props
  return (
    <FormControl className={classes.formControl}>
       <Input
          {...props}
          classes={{
              input: classes.input,
              disabled: classes.disabled,
              underline: classes.underline
            }}
          inputRef={ref}
          fullWidth
          size='small'
          label='Phone Number'
          variant='outlined'
          name='phone'
        />
    </FormControl>
  );
}
)

export default withStyles(customInputStyle)(CustomPhoneNumberInput);
