import axios from 'axios';

export async function fetchCountersByOrganizationId (organizationId) {
  const result = await axios.get(`/${organizationId}/counter`)
  if(result.data.success) {
    return result.data.object
  }

  return false
}

export async function createCounter (organizationId, body) {
  const result =  await axios.post(`/${organizationId}/counter`, body)
  if(result.data.success) {
    return result.data.object
  }

  return false
}

export async function deleteCounter(organizationId, counterId) {
  const result = await axios.delete(`/${organizationId}/counter/${counterId}`)
  if(result.data.success) {
    return result.data.object
  }
  console.log(result)
  return false
}

export async function editCounter(organizationId, body) {
  const result = await axios.put(`/${organizationId}/counter`,body)
  if(result.data.success) {
    return result.data.object
  }
  console.log(result)
  return false
}


