import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import { makeStyles } from "@material-ui/core/styles";
import AuthHeader from 'components/Header/AuthHeader';
import authRoutes from 'routes/auth.js';
//import pagesStyle from 'assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx';
import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import bgImage from 'assets/img/register.jpeg';
/*
class Auth extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <AuthHeader {...rest} />
        <div className={classes.wrapper} ref="wrapper">
          <div className={classes.fullPage}>
            <Switch>
              {authRoutes.map((prop, key) => {
                if (prop.collapse) {
                  return null;
                }
                if (prop.redirect) {
                  return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                }
                return <Route path={prop.path} component={prop.component} key={key} />;
              })}
            </Switch>
            <div
              className={classes.fullPageBackground}
              style={{ backgroundImage: `url(${bgImage})` }}
            />
          </div>
        </div>
      </div>
    );
  }
}
*/


const useStyles = makeStyles(pagesStyle);

export default function Pages(props) {
  const { ...rest } = props;
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return null;
      }
      if (prop.redirect) {
        return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
      }
      return <Route path={prop.path} component={prop.component} key={key} />;
    });
  };
  const getBgImage = () => {
    return bgImage;
  };
  return (
    <div>
      <AuthHeader {...rest} />
      <div className={classes.wrapper} ref={wrapper}>
        <div
          className={classes.fullPage}
          style={{backgroundColor:"#eeeeee"}}
        >
          <Switch>
            {getRoutes(authRoutes)}
          </Switch>
        </div>
      </div>
    </div>
  );
}
