import React, { useState, useEffect } from "react";
import moment from "moment-timezone"
import {
    FormControlLabel,
    FormLabel,
    FormControl,
    Grid,
    Switch,
    Tooltip,
    Checkbox,
} from '@material-ui/core';
import Check from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Datetime from "react-datetime";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import validationFormStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle';
import { defaultWorkingHoursObj } from 'constants.js';
import nonMutatingArray from "utils/nonMutatingArray";
import { FormHelperText } from '@material-ui/core';

const useStyles = makeStyles({ ...validationFormStyle });

const CustomWorkingHours = (props) => {

    const classes = useStyles();

    const defaultTimeObj = {
        startTime: {
            hour: 0,
            minute: 0,
        },
        endTime: {
            hour: 0,
            minute: 0,
        },
    }
    const dayListOptions = [
        'All',
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ];


    const defaultWorkingHours = [
        {
            "day": "Sunday",
            "startTime": {
                "hour": 8,
                "minute": 0
            },
            "endTime": {
                "hour": 18,
                "minute": 0
            }
        },
        {
            "day": "Monday",
            "startTime": {
                "hour": 8,
                "minute": 0
            },
            "endTime": {
                "hour": 18,
                "minute": 0
            }
        },
        {
            "day": "Tuesday",
            "startTime": {
                "hour": 8,
                "minute": 0
            },
            "endTime": {
                "hour": 18,
                "minute": 0
            }
        },
        {
            "day": "Wednesday",
            "startTime": {
                "hour": 8,
                "minute": 0
            },
            "endTime": {
                "hour": 18,
                "minute": 0
            }
        },
        {
            "day": "Thursday",
            "startTime": {
                "hour": 8,
                "minute": 0
            },
            "endTime": {
                "hour": 18,
                "minute": 0
            }
        },
        {
            "day": "Friday",
            "startTime": {
                "hour": 8,
                "minute": 0
            },
            "endTime": {
                "hour": 18,
                "minute": 0
            }
        },
        {
            "day": "Saturday",
            "startTime": {
                "hour": 8,
                "minute": 0
            },
            "endTime": {
                "hour": 18,
                "minute": 0
            }
        }
    ];


    const { workingHours, onChange, placeholder, disabled, id, initialValue, helperText, error } = props;

    const [intitialWHValue, setIntitialWHValue] = useState(null);
    const [workingHoursValue, setWorkingHoursValue] = useState(null);
    const [advancedMode, setAdvancedMode] = useState(false);
    const [commonTimeObj, setCommonTimeObj] = useState(defaultTimeObj);
    const [selectedDays, setSelectedDays] = useState(dayListOptions);

    const onChangeCommonTime = (date, period) => {
        let commonTimeObj_ = commonTimeObj

        let dateObj = date;
        if (typeof date === 'string') {
            const unix = moment(date, 'hh:mm A').unix();
            if (!unix) return;
            dateObj = moment(unix * 1000);
        }
        if (!dateObj) return;

        const time = moment(dateObj);
        commonTimeObj_[period] = {
            hour: time.hour(),
            minute: time.minutes()
        }
        setCommonTimeObj(commonTimeObj_);

        let workingHours_ = workingHoursValue;
        workingHours_ = workingHours_.map((whObj) => {
            let whObj_ = whObj;
            if (selectedDays.includes(whObj_.day)) {
                whObj_[period] = commonTimeObj_[period];
            }
            return whObj_
        })
        setWorkingHoursValue([...workingHours_]);

        if (onChange)
            onChange([...workingHours_])

    }
    const handleDayToggle = (day) => {
        let selectedDays_ = selectedDays;
        let workingHours_ = workingHoursValue;
        let whIndex = workingHours_.findIndex((wH) => wH.day == day);
        if (selectedDays_.includes(day)) {
            var dayIndex = selectedDays_.indexOf(day);
            if (dayIndex !== -1) {
                selectedDays_.splice(dayIndex, 1);
            }
            /* uncheck All option */
            var allIndex = selectedDays_.indexOf('All');
            if (allIndex !== -1) {
                selectedDays_.splice(allIndex, 1);
            }
            /* Set default 0 to 0 working hour for that day  */
            let zeroTime = {
                hour: 0,
                minute: 0
            }
            if (day == 'All') {
                selectedDays_ = []
                workingHours_ = workingHours_.map(wH => {
                    let wH_ = wH
                    wH_["startTime"] = zeroTime
                    wH_["endTime"] = zeroTime
                    return wH_
                })
            } else {
                if (whIndex >= 0) {
                    workingHours_[whIndex]["startTime"] = zeroTime
                    workingHours_[whIndex]["endTime"] = zeroTime
                }
            }
        } else {
            selectedDays_ = [...selectedDays_, day];
            if (day == 'All') {
                selectedDays_ = dayListOptions
                workingHours_ = workingHours_.map(wH => {
                    let wH_ = { ...wH, ...commonTimeObj, }
                    return wH_
                })
            } else {
                if (whIndex >= 0) {
                    workingHours_[whIndex]["startTime"] = commonTimeObj.startTime
                    workingHours_[whIndex]["endTime"] = commonTimeObj.endTime
                }
            }
        }
        /*
        console.log(" handleDayToggle workingHours_id:" + id, workingHours_)
        console.log(" handleDayToggle intitialWHValue:" + id, intitialWHValue)
        console.log(" handleDayToggle workingHoursValue:" + id, workingHoursValue)
        console.log(" handleDayToggle defaultWorkingHoursObj:" + id, defaultWorkingHours)
        */

        setSelectedDays([...selectedDays_]);
        setWorkingHoursValue([...workingHours_]);
        if (onChange)
            onChange([...workingHours_])
    }
    const onChangeWorkingHour = (date, index, period) => {
        let dateObj = date;
        if (typeof date === 'string') {
            const unix = moment(date, 'hh:mm A').unix();
            if (!unix) return;
            dateObj = moment(unix * 1000);
        }
        if (!dateObj) return;
        let workingHours_ = workingHoursValue;
        const time = moment(dateObj);
        let tmpTime = {
            hour: time.hour(),
            minute: time.minutes()
        }
        workingHours_[index][period] = tmpTime;
        setWorkingHoursValue([...workingHours_]);

        if (onChange)
            onChange([...workingHours_])

        //console.log("workingHours >> ", date, workingHours_)
    }

    useEffect(() => {
        if (workingHours?.length) {
            if (!intitialWHValue || disabled) {
                setIntitialWHValue(nonMutatingArray(workingHours));
            }
            const workingHours_ = nonMutatingArray(workingHours);
            //console.log("WorkHour changed", workingHours)
            setWorkingHoursValue([...workingHours_])
        } else {
            const workingHours_ = defaultWorkingHours;
            if (!intitialWHValue || disabled) {
                setIntitialWHValue(nonMutatingArray(workingHours));
            }

            setWorkingHoursValue([...workingHours_])
        }
    }, [workingHours])

    useEffect(() => {
        if (intitialWHValue?.length) {
            let advancedMode_ = false;
            let selectedDays_ = dayListOptions;
            let commonTimeObj_ = null;

            for (const whDay of intitialWHValue) {
                if (whDay?.day && whDay?.startTime, whDay?.startTime && whDay?.endTime && JSON.stringify(whDay.startTime) == JSON.stringify(whDay.endTime)) {
                    var dayIndex = selectedDays_.indexOf(whDay.day);
                    if (dayIndex !== -1) {
                        selectedDays_.splice(dayIndex, 1);
                    }
                    /* uncheck All option */
                    var allIndex = selectedDays_.indexOf('All');
                    if (allIndex !== -1) {
                        selectedDays_.splice(allIndex, 1);
                    }
                } else {
                    if (advancedMode_)
                        continue
                    let tmpTimeObj = { startTime: whDay?.startTime, endTime: whDay?.endTime }
                    if (commonTimeObj_ && JSON.stringify(commonTimeObj_) !== JSON.stringify(tmpTimeObj)) {
                        advancedMode_ = true;
                    } else {
                        commonTimeObj_ = tmpTimeObj
                    }
                }
            }
            if (commonTimeObj_) {
                setCommonTimeObj(commonTimeObj_)
            }
            setAdvancedMode(advancedMode_)
            setSelectedDays(selectedDays_)

        }
    }, [intitialWHValue])

    
    useEffect(()=>{
        if(initialValue && initialValue.length && JSON.stringify(intitialWHValue) !== JSON.stringify(initialValue) ){
            setIntitialWHValue(nonMutatingArray(initialValue));
            setWorkingHoursValue(nonMutatingArray(initialValue))
        }
    },[initialValue]);



    return (
        <>
            <GridContainer alignItems="flex-start" style={{ minHeight: 50 }}>
                <GridItem>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={advancedMode}
                                onChange={(event) => setAdvancedMode(event.target.checked)}
                                value={"checked-mode"}
                                disabled={disabled ? true : false}
                                classes={{
                                    switchBase: classes.switchBase,
                                    checked: classes.switchChecked,
                                    thumb: classes.switchIcon,
                                    track: classes.switchBar,
                                }}
                            />
                        }
                        classes={{
                            label: classes.label,
                        }}
                        label={"Custom Timing"}
                    />
                </GridItem>
            </GridContainer>
            {!advancedMode ?
                <GridContainer alignItems="flex-start" style={{ minHeight: 50 }}>
                    <GridItem xs={12} md={12} lg={6}>
                        <GridContainer alignItems="flex-start" justifyContent="flex-start">
                            <GridItem xs={5} md={5} lg={4}>
                                <FormControl fullWidth >
                                    <Datetime
                                        dateFormat={false}
                                        inputProps={{
                                            placeholder: 'Start Time',
                                            name: `startTime`,
                                            disabled: disabled ? true : false,
                                        }}
                                        formControlProps={{ fullWidth: true }}
                                        onChange={(date) => onChangeCommonTime(date, "startTime")}
                                        value={moment().set(commonTimeObj.startTime).toDate()}
                                        timeConstraints={{minutes:{step:5}}}
                                    />
                                </FormControl>
                            </GridItem>
                            <GridItem xs={2} md={2} lg={2}><label className={classes.label} style={{ paddingTop: 10 }}> TO </label></GridItem>
                            <GridItem xs={5} md={5} lg={4}>
                                <FormControl fullWidth >
                                    <Datetime
                                        dateFormat={false}
                                        inputProps={{
                                            placeholder: 'End Time',
                                            name: `endTime`,
                                            disabled: disabled ? true : false,
                                        }}
                                        formControlProps={{ fullWidth: true }}
                                        onChange={(date) => onChangeCommonTime(date, "endTime")}
                                        value={moment().set(commonTimeObj.endTime).toDate()}
                                        timeConstraints={{minutes:{step:5}}}
                                        className="drop-to-the-right"
                                    />

                                </FormControl>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12} md={12} lg={12}>
                        <GridContainer alignItems="flex-start" justifyContent="flex-start">
                            {dayListOptions.map((dayOption, index) => (
                                <GridItem key={dayOption}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onClick={() => handleDayToggle(dayOption)}
                                                checked={selectedDays.includes(dayOption)}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot,
                                                }}
                                                disabled={disabled ? true : false}
                                            />
                                        }
                                        classes={{
                                            label: classes.label,
                                            root: classes.labelRoot,
                                        }}
                                        label={dayOption}
                                    />
                                </GridItem>
                            ))
                            }
                        </GridContainer>
                    </GridItem>
                </GridContainer>
                :
                workingHoursValue && workingHoursValue.map((day, index) => (
                    <GridContainer alignItems="flex-start" key={index} style={{ marginTop: 10, minHeight: 50 }}>
                        <GridItem
                            xs={4}
                            md={3}
                            lg={3}
                            xl={2}
                            className={classes.workingHours}
                            key={index}
                        >
                            <label className={classes.label} style={{ paddingTop: 10 }}>{day?.day}</label>
                        </GridItem>
                        <GridItem xs={4} md={3} lg={3}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={selectedDays.includes(day.day)}
                                        onChange={(event) => handleDayToggle(day.day)}
                                        value={"checked-" + day?.day}
                                        disabled={disabled ? true : false}
                                        classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.switchChecked,
                                            thumb: classes.switchIcon,
                                            track: classes.switchBar,
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label,
                                }}
                                label={selectedDays.includes(day.day) ? "Available" : "Unavailable"}
                            />
                        </GridItem>
                        {selectedDays.includes(day.day) &&
                            <GridItem xs={12} md={12} lg={6}>
                                <GridContainer alignItems="flex-start" justifyContent="flex-start">
                                    <GridItem xs={5} md={5} lg={4}>
                                        <FormControl fullWidth >
                                            <Datetime
                                                dateFormat={false}
                                                inputProps={{
                                                    placeholder: 'Start Time',
                                                    name: `startTime`,
                                                    disabled: disabled ? true : false,
                                                }}
                                                formControlProps={{ fullWidth: true }}
                                                onChange={(date) => onChangeWorkingHour(date, index, "startTime")}
                                                value={moment().set(day.startTime).toDate()}
                                                timeConstraints={{minutes:{step:5}}}
                                            />
                                        </FormControl>
                                    </GridItem>
                                    <GridItem xs={2} md={2} lg={2}><label className={classes.label} style={{ paddingTop: 10 }}> TO </label></GridItem>
                                    <GridItem xs={5} md={5} lg={4}>
                                        <FormControl fullWidth >
                                            <Datetime
                                                dateFormat={false}
                                                inputProps={{
                                                    placeholder: 'End Time',
                                                    name: `endTime`,
                                                    disabled: disabled ? true : false,
                                                }}
                                                formControlProps={{ fullWidth: true }}
                                                onChange={(date) => onChangeWorkingHour(date, index, "endTime")}
                                                value={moment().set(day.endTime).toDate()}
                                                timeConstraints={{minutes:{step:5}}}
                                                className="drop-to-the-right"
                                            />

                                        </FormControl>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        }
                    </GridContainer>
                ))}
            {helperText ? 
                <FormHelperText error={error?true:false}>{helperText}</FormHelperText>
                :null
            }
            
        </>
    )
}

export default React.memo(CustomWorkingHours);