import axios from 'axios';
import { organization } from '../constants/Organization.constants';
import { URL } from '../config/config';
import { showAlert } from './alert';

export const fetchOrganization = id => {
  return dispatch => {
    dispatch({ type: organization.FETCH_ORGANIZATION_LOADING });
    axios.get(`${URL.ORGANIZATION}/${id}`)
      .then(({ data }) => {
        dispatch({
          type: organization.FETCH_ORGANIZATION_SUCCESS,
          payload: data.object
        });
      })
      .catch(err => {
        dispatch({
          type: organization.FETCH_ORGANIZATION_FAILURE,
          payload: err
        });
      });
  };
};

export const editOrganizationLoading = () => {
  return {
    type: organization.EDIT_ORGANIZATION_LOADING
  };
};

export const editOrganizationSuccess = data => {
  return {
    type: organization.EDIT_ORGANIZATION_SUCCESS,
    payload: data
  };
};

export const editOrganizationFailure = error => {
  return {
    type: organization.EDIT_ORGANIZATION_FAILURE,
    payload: error
  };
};

export const editOrganization = (values, history, isSuperAdmin) => {
  return dispatch => {
    dispatch(editOrganizationLoading());
    axios.put(URL.ORGANIZATION, values)
      .then(({ data }) => {
        if(data.success){
          dispatch(editOrganizationSuccess(data));
          dispatch(showAlert('success', 'Organization edited successfully'));
          if(isSuperAdmin){
            history.push('/organization/superadmin/list');
          } else {
            history.push(`/organization/edit/${values.id}`);
          }
        }else{
          let err = data?.message ? data.message :'';
          dispatch(editOrganizationFailure(err));
          dispatch(showAlert('error', 'Organization Edit Failed : '+err));
        }
      })
      .catch(err => {
        dispatch(editOrganizationFailure(err));
        dispatch(showAlert('error', 'Organization Edit Failed : '+err));
      });
  };
};

export const fetchOrganizationsLoading = () => {
  return {
    type: organization.FETCH_ORGANIZATIONS_LOADING
  };
};

export const fetchOrganizationsSuccess = organizations => {
  return {
    type: organization.FETCH_ORGANIZATIONS_SUCCESS,
    payload: { organizations }
  };
};

export const fetchOrganizationsFailure = error => {
  return {
    type: organization.FETCH_ORGANIZATIONS_FAILURE,
    payload: { error }
  };
};

export const fetchOrganizationsByBusinessAdminId = () => {
  return dispatch => {
    dispatch(fetchOrganizationsLoading());
    return axios.get(`${URL.FETCH_ORGANIZATIONS_BY_BUSINESS_ADMIN_ID}`)
      .then(({ data }) => {
        dispatch(fetchOrganizationsSuccess(data.objects));
        return data?.objects
      })
      .catch(err => {
        dispatch(fetchOrganizationsFailure('Cannot fetch organizations'));
      });
  };
};

export const fetchOrganizationsBySuperAdmin = () => {
  return dispatch => {
    dispatch(fetchOrganizationsLoading());
    axios.get(`${URL.FETCH_ORGANIZATIONS_BY_SUPER_ADMIN}`)
      .then(({ data }) => {
        dispatch(fetchOrganizationsSuccess(data.objects));
      })
      .catch(err => {
        dispatch(fetchOrganizationsFailure('Cannot fetch organizations'));
      });
  };
};

export const createOrganizationLoading = () => {
  return {
    type: organization.CREATE_ORGANIZATION_LOADING
  };
};

export const createOrganizationSuccess = data => {
  return {
    type: organization.CREATE_ORGANIZATION_SUCCESS,
    payload: { data }
  };
};

export const createOrganizationFailure = error => {
  return {
    type: organization.CREATE_ORGANIZATION_FAILURE,
    payload: { error }
  };
};

export const createOrganization = (values, history, isSuperAdmin, callback) => {
  return dispatch => {
    dispatch(createOrganizationLoading());
    axios.get(URL.ORGANIZATION_NAME_VALIDATE + values.name)
      .then((resp) => {
        if (resp.data.object === 'VALID') {
          axios.post(URL.ORGANIZATION, values)
            .then(({ data }) => {
              if (data.success) {
                dispatch(createOrganizationSuccess(data));
                dispatch(showAlert('success', 'Organization created successfully'));
                if (callback) {
                  callback(data);
                } else {
                  if(isSuperAdmin){
                    history.push('/organization/superadmin/list');
                  } else {
                    history.push('/organization/list');
                  }
                }}
              else {
                dispatch(createOrganizationFailure(data));
              }
            })
            .catch(err => {
              dispatch(createOrganizationFailure(err));
            })
        } else {
          alert('Already registered organization. Please enter a unique name')
        }
      })
      .catch(err => console.log('error', err));
  }
}

export const isUniqueOrganizationName = (values)=>{
  return dispatch => {
    return axios.get(URL.ORGANIZATION_NAME_VALIDATE + values.name)
      .then((resp) => {
        if (resp.data.object === 'VALID') {
          return true
        }else{
          return false
        }
      })
  }
}
export const createOrganizationWizard = (values) => {
  return dispatch => {

      return axios.post(URL.ORGANIZATION, values)
        .then(({ data }) => {
          if (data.success) {
            dispatch(createOrganizationSuccess(data));  
          }
          else {
            dispatch(createOrganizationFailure(data));
          }
          return data;
        })
        .catch(err => {
          dispatch(createOrganizationFailure(err));
          console.log('error', err)
        })
        
  }
}


export const setZoomCredentials = data => {
  return {
    type: organization.SET_ZOOM_CREDENTIALS,
    payload: data
  };
};

export const fetchZoomCredentialsByOrgId = orgId => async dispatch => {
  try {
    const response = await axios.get(`${URL.ZOOM_CREDENTIALS}/${orgId}`);
    if (response?.data?.success && response?.data?.object) {
      dispatch(setZoomCredentials(response?.data?.object));
      return response?.data?.object
    }
  }catch(e){
    console.log("Error [fetchZoomCredentialsByOrgId] !! ",e);
    dispatch(showAlert("error", e?.message ?? "Something went wrong"));
  }

};
export const saveZoomCredentials = payload => async dispatch => {
  try {
    const response = await axios.post(`${URL.ZOOM_CREDENTIALS}/save`, payload);
    if (response?.data?.success && response?.data?.object) {
        dispatch(setZoomCredentials(response?.data?.object));
        dispatch(showAlert("success", "Zoom SDK Credentials saved successfully"))
        return true;
    } else {
        const message = response?.data?.message ?? "Failed to save Zoom SDK Credentials";
        dispatch(showAlert("error", message));
    }
  }catch(e){
    console.log("Error [saveZoomCredentials] !! ",e);
    dispatch(showAlert("error", e?.message ?? "Something went wrong"));
  }
};

export const setOrgPaymentMethods = data => {
  return {
    type: organization.SET_ORG_PAYMENT_METHODS,
    payload: data
  };
};
export const setOrgPaymentMethodOptions = data => {
  return {
    type: organization.SET_ORG_PAYMENT_METHODS_OPTIONS,
    payload: data
  };
};
export const fetchOrganizationPaymentMethods = () => async dispatch => {
  try {
    const response = await axios.get(`${URL.ORG_PAYMENT_METHODS}`);
    if (response?.data?.success && response?.data?.object) {
      const paymentData = response?.data?.object
      dispatch(setOrgPaymentMethods(paymentData));
      const options = paymentData?.paymentMethods?.length ? paymentData?.paymentMethods?.map(item=>({ label: item.replace(/\b\w/g, char => char.toUpperCase()), value:item, key:item})) : [];
      dispatch(setOrgPaymentMethodOptions(options));
      return response?.data?.object
    }
  }catch(e){
    console.log("Error [fetchOrganizationPaymentMethods] !! ",e);
    dispatch(showAlert("error", e?.message ?? "Something went wrong"));
  }

};
export const saveOrganizationPaymentMethods = payload => async dispatch => {
  try {
    const response = await axios.post(`${URL.ORG_PAYMENT_METHODS}`, payload);
    if (response?.data?.success && response?.data?.object) {
        dispatch(setOrgPaymentMethods(response?.data?.object));
        dispatch(showAlert("success", "Payment method details saved successfully"))
        return true;
    } else {
        const message = response?.data?.message ?? "Failed to save payment method details";
        dispatch(showAlert("error", message));
    }
  }catch(e){
    console.log("Error [saveOrganizationPaymentMethods] !! ",e);
    dispatch(showAlert("error", e?.message ?? "Something went wrong"));
  }
};

