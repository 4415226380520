import React from 'react'
import { useEffect, useState, lazy } from 'react'
import { connect } from 'react-redux'
import {
	Grid,
	Typography,
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from '@material-ui/core';
import {
	SettingsOutlined,
	AccountCircleOutlined,
	ExpandMore,
	PersonOutlined,
	Business,
} from '@material-ui/icons';
import { managementRoutes, operationRoutes, providerRoutes, profileRouteComponent, superAdminRoutes } from 'routes/dashboard';
import styles from './Dashboard.module.scss';
import { eUserType } from 'constants.js';
import Item from './components/Item';
import { fetchOrganizationsByBusinessAdminId } from "actions/organization";
import { useHistory } from "react-router-dom";

function Dashboard({
	userDetail,
	organizations,
	fetchOrganizationsByBusinessAdminId,
}) {

	const history = useHistory();

	useEffect(
		() => {
			onUserDetailsChange(userDetail);
		},
		[userDetail]
	)
	const onUserDetailsChange = async (userDetail) => {

		console.log("userDetail Changed")
		if (userDetail && [eUserType.provider,eUserType.business_admin, eUserType.super_admin].includes(userDetail?.userType)) {
			
			const businesAdminId =  userDetail?.userType === eUserType.provider && userDetail?.providerInformation?.businessId ? 
				userDetail?.providerInformation?.businessId
			 :  userDetail?.id ;
			const organizations = await fetchOrganizationsByBusinessAdminId(businesAdminId);
			console.log("organizations >>", organizations)
			if ( !organizations.length){
				navigateToWelcomeWizard()
			}
		}
	}

	const navigateToWelcomeWizard = () => {
		history.replace('/welcomeWizard');
	}

	if (!userDetail) return null;

	const isSuperAdmin = userDetail.userType ? (userDetail.userType === eUserType.super_admin) : false;
	const isProvider = userDetail.userType ? (userDetail.userType === eUserType.provider && !(userDetail.providerInformation && userDetail.providerInformation.isAdmin)) : true;

	return (
		<div className={styles.wrapper}>
			{
				isSuperAdmin && (
					<Accordion defaultExpanded>
						<AccordionSummary
							expandIcon={<ExpandMore />}
							aria-controls="management-content"
							id="management-header"
						>
							<SettingsOutlined
								className={styles.headingIcon}
								fontSize="large"
								color="disabled"
							/>
							<Typography
								data-test-id="managementHeading"
								display="inline"
								variant="h6"
							>
								Super Admin Tools
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Grid
								container
								spacing={2}
								alignItems='center'
							>
								{
									superAdminRoutes.map(route => {
										return (
											<Item key={route.path} routeInfo={route} />
										);
									})
								}
							</Grid>
						</AccordionDetails>
					</Accordion>
				)
			}
			{!isProvider && (
				<Accordion defaultExpanded>
					<AccordionSummary
						expandIcon={<ExpandMore />}
						aria-controls="management-content"
						id="management-header"
					>
						<SettingsOutlined
							className={styles.headingIcon}
							fontSize="large"
							color="disabled"
						/>
						<Typography
							data-test-id="managementHeading"
							display="inline"
							variant="h6"
						>
							Management
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Grid
							container
							spacing={2}
							alignItems='center'
						>
							{organizations.length > 0 ?
								<Item
									key={'/organization/edit'}
									routeInfo={{
										path: `/organization/edit/${organizations[0].id}`,
										name: 'Organization',
										shortName: 'OE',
										component: lazy(() => import('views/Organization/OrganizationEdit')),
										icon: Business,
										iconColor: 'secondary',
										dataTestId: 'orgCreateNavLink',
									}}
								/>
								:
								<Item
									key={'/organization/create'}
									routeInfo={{
										path: '/organization/create',
										name: 'Organization',
										shortName: 'OC',
										component: lazy(() => import('views/Organization/OrganizationCreate')),
										icon: Business,
										iconColor: 'secondary',
										dataTestId: 'orgEditNavLink',
									}}
								/>
							}
							{managementRoutes.map(route => {
								if (isProvider && !providerRoutes.includes(route.path)) {
									return null;
								}
								return (
									<Item
										key={route.path}
										routeInfo={route}
									/>
								)
							})}
						</Grid>
					</AccordionDetails>
				</Accordion>)}
			<Accordion defaultExpanded>
				<AccordionSummary
					expandIcon={<ExpandMore />}
					aria-controls="operation-content"
					id="operation-header"
				>
					<AccountCircleOutlined className={styles.headingIcon} fontSize="large" color="disabled" />
					<Typography data-test-id="customersHeading" display="inline" variant="h6">Operation</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Grid
						container
						spacing={2}
						alignItems='center'
					>
						{operationRoutes.map(route => {
							if (isProvider && !providerRoutes.includes(route.path)) {
								return null;
							}
							return (
								<Item key={route.path} routeInfo={route} />
							);
						})}
					</Grid>
				</AccordionDetails>
			</Accordion>
			<Accordion defaultExpanded>
				<AccordionSummary
					expandIcon={<ExpandMore />}
					aria-controls="account-content"
					id="account-header"
				>
					<PersonOutlined className={styles.headingIcon} fontSize="large" color="disabled" />
					<Typography data-test-id="customersHeading" display="inline" variant="h6">Account</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Grid
						container
						spacing={2}
						alignItems='center'
					>
						<Item routeInfo={profileRouteComponent} />
					</Grid>
				</AccordionDetails>
			</Accordion>
		</div>
	)
};

const mapStateToProps = state => ({
	userDetail: state.user.userDetail,
	organizations: state.organization.organizations,
})

const mapDispatchToProps = dispatch => ({
	fetchOrganizationsByBusinessAdminId: id => dispatch(fetchOrganizationsByBusinessAdminId(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

