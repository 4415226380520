import axios from 'axios';
import { URL } from 'config/config';
import { service_options } from '../constants/ServiceOptions.constants';

export const setServiceOptionsSuccess = payload => ({
  type: service_options.FETCH_SERVICES_OPTIONS_SUCCESS,
  payload
});

export const setServiceOptionsLoading = payload => ({
  type: service_options.FETCH_SERVICES_OPTIONS_LOADING,
  payload
});

const sorting_function = (object_a,object_b) => {
    let string_a = object_a.label.toLowerCase()
    let string_b = object_b.label.toLowerCase()

    if (string_a > string_b) {
        return 1
    }
    if (string_a < string_b) {
        return -1
    }
    return 0
}
export const fetchServiceOptionsByBusinessAdminId = () => dispatch => {
  dispatch(setServiceOptionsLoading(true))
  return axios.get(`${URL.FETCH_SERVICES_OPTION_BY_BUSINESS_ADMIN_ID}/`)
    .then(res => {
      if (res && res.data.success) {
        dispatch(setServiceOptionsSuccess(res.data.objects.sort(sorting_function)))
      }
    }).finally(()=>{
      dispatch(setServiceOptionsLoading(false))
    })
}

export const fetchServiceOptionsByProviderId = providerId => dispatch => {
  dispatch(setServiceOptionsLoading(true));
  return axios.get(`${URL.FIND_SERVICES_BY_PROVIDER_ID}/${providerId}`)
    .then(res => {
      if (res && res.data.success) {
        dispatch(setServiceOptionsSuccess(res.data.objects));
      }
    }).finally(()=>{
      dispatch(setServiceOptionsLoading(false));
    });
};
