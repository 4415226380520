
import axios from 'axios';
import { get } from 'lodash';
import { URL } from 'config/config';
import { showAlert } from './alert';

export const FETCH_PRODUCTS_LOADING = 'FETCH_PRODUCTS_LOADING';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR';
export const FETCH_PRODUCT_DETAILS = 'FETCH_PRODUCT_DETAILS';



const fetchProductsLoading = (value = true) => ({
    type: FETCH_PRODUCTS_LOADING,
    payload: value
});
const fetchProductsSuccess = payload => ({
    type: FETCH_PRODUCTS_SUCCESS,
    payload
});
const fetchProductsFailure = payload => ({
    type: FETCH_PRODUCTS_ERROR,
    payload
});
const setProduct = payload => ({
    type: FETCH_PRODUCT_DETAILS,
    payload
});


export const fetchProducts = () => async dispatch => {
    dispatch(fetchProductsLoading(true));
    try {
        
        const response = await axios.get(URL.PRODUCTS);
        if (response && response.data.success) {
            const data = get(response, 'data.objects', []);
            dispatch(fetchProductsSuccess(data));
            dispatch(fetchProductsLoading(false));
            return data
        }
    } catch (err) {
        dispatch(fetchProductsFailure(err));
        dispatch(fetchProductsLoading(false));
    };
}

export const createProduct = (payload) => async dispatch => {
    try {
        const response = await axios.post(URL.PRODUCTS, payload);
        if (response && response.data.success) {
            const data = get(response, 'data.object', []);
            dispatch(fetchProducts())
            return data
        } else if (response?.data?.message) {

            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}

export const fetchProductById = (productId) => async dispatch => {
    dispatch(fetchProductsLoading(true));
    try {
        const response = await axios.get(`${URL.PRODUCTS}/${productId}`);
        if (response && response.data.success) {
            const data = get(response, 'data.object', {});
            dispatch(setProduct(data));
            dispatch(fetchProductsLoading(false));
            return data
        } else if (response?.data?.message) {

            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}

export const editProduct = (payload) => async dispatch => {
    try {
        const response = await axios.put(URL.PRODUCTS, payload);
        if (response && response.data.success) {
            const data = get(response, 'data.object', []);
            dispatch(fetchProducts())
            return data
        } else if (response?.data?.message) {
            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}

export const deleteProduct = (productId) => async dispatch => {
    try {
        const response = await axios.delete(URL.PRODUCTS + `/${productId}`);
        if (response && response.data.success) {
            dispatch(fetchProducts())
            return true
        } else if (response?.data?.message) {
            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}