export const service = {
  FETCH_SERVICE_LOADING: 'FETCH_SERVICE_LOADING',
  FETCH_SERVICE_SUCCESS: 'FETCH_SERVICE_SUCCESS',
  FETCH_SERVICE_FAILURE: 'FETCH_SERVICE_FAILURE',

  FETCH_SERVICES_LOADING: 'FETCH_SERVICES_LOADING',
  FETCH_SERVICES_SUCCESS: 'FETCH_SERVICES_SUCCESS',
  FETCH_SERVICES_FAILURE: 'FETCH_SERVICES_FAILURE',

  CREATE_SERVICE_LOADING: 'CREATE_SERVICE_LOADING',
  CREATE_SERVICE_SUCCESS: 'CREATE_SERVICE_SUCCESS',
  CREATE_SERVICE_FAILURE: 'CREATE_SERVICE_FAILURE',

  FETCH_CATEGORIES_LOADING: 'FETCH_CATEGORIES_LOADING',
  FETCH_CATEGORIES_SUCCESS: 'FETCH_CATEGORIES_SUCCESS',
  FETCH_CATEGORIES_FAILURE: 'FETCH_CATEGORIES_FAILURE',

  EDIT_SERVICE_LOADING: 'EDIT_SERVICE_LOADING',
  EDIT_SERVICE_FAILURE: 'EDIT_SERVICE_FAILURE',
  EDIT_SERVICE_SUCCESS: 'EDIT_SERVICE_SUCCESS',

  DEL_SERVICE_LOADING: 'DEL_SERVICE_LOADING',
  DEL_SERVICE_SUCCESS: 'DEL_SERVICE_SUCCESS',
  DEL_SERVICE_FAILURE: 'DEL_SERVICE_FAILURE'
};

export const serviceMode = {
  QUE: 'QUEUE',
  SCHEDULE: 'SCHEDULE'
}
