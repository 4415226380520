export const provider = {
  FETCH_PROVIDERS_LOADING: 'FETCH_PROVIDERS_LOADING',
  FETCH_PROVIDERS_SUCCESS: 'FETCH_PROVIDERS_SUCCESS',
  FETCH_ADMINS_SUCCESS: 'FETCH_ADMINS_SUCCESS',
  FETCH_PROVIDERS_FAILURE: 'FETCH_PROVIDERS_FAILURE',
  FETCH_CUSTOMER_SUCCESS: 'FETCH_CUSTOMER_SUCCESS',

  CREATE_PROVIDER_LOADING: 'CREATE_PROVIDER_LOADING',
  CREATE_PROVIDER_SUCCESS: 'CREATE_PROVIDER_SUCCESS',
  CREATE_PROVIDER_FAILURE: 'CREATE_PROVIDER_FAILURE',

  EDIT_PROVIDER_LOADING: 'EDIT_PROVIDER_LOADING',
  EDIT_PROVIDER_SUCCESS: 'EDIT_PROVIDER_SUCCESS',
  EDIT_PROVIDER_FAILURE: 'EDIT_PROVIDER_FAILURE',

  DEL_PROVIDER_LOADING: 'DEL_PROVIDER_LOADING',
  DEL_PROVIDER_SUCCESS: 'DEL_PROVIDER_SUCCESS',
  DEL_PROVIDER_FAILURE: 'DEL_PROVIDER_FAILURE',

  FETCH_PROVIDER_LOADING: 'FETCH_PROVIDER_LOADING',
  FETCH_PROVIDER_SUCCESS: 'FETCH_PROVIDER_SUCCESS',
  FETCH_PROVIDER_FAILURE: 'FETCH_PROVIDER_FAILURE',

  FETCH_TIMEZONES_LOADING: 'FETCH_TIMEZONES_LOADING',
  FETCH_TIMEZONES_SUCCESS: 'FETCH_TIMEZONES_SUCCESS',
  FETCH_TIMEZONES_FAILURE: 'FETCH_TIMEZONES_FAILURE',
  CLEAR_PROVIDERS: 'CLEAR_PROVIDERS',
};

export const emailSubscriptionType = Object.freeze({
  Never: 2,
  Daily: 0,
  Weekly: 1
});
