import { combineReducers } from 'redux';

import commonReducer from './common';
import ProvidersReducer from './provider.reducer';
import UserReducer from './auth.reducer';
import TemplateReducer from './email_templates';
import OrganizationReducer from './organization.reducer';
import ServiceReducer from './service.reducer';
import ImageUploadReducer from './imageUpload.reducer';
import PictureUploadReducer from './pictureUpload.reducer';
import LocationReducer from './location.reducer';
import manageCalendarReducer from './calendar.reducer';
import tmpServicesReducer from './tmpServices.reducer';
import eventServicesReducer from './eventServices.reducer';
import customerServiceReducer from './customerService.reducer';
import serviceOptionsReducer from './serviceOptions.reducer';
import counterOptionsReducer from './counterOptions.reducer';
import serviceCategoryReducer from './serviceCategory.reducer';
import businessCategoryReducer from './businessCategory.reducer';
import tmpServiceDetailReducer from './tmpServiceDetail.reducer';
import availabilitySlotsReducer from './availabilitySlots.reducer';
import scheduleReportsReducer from './scheduleReport.reducer';
import timezoneOptionsReducer from './timezoneOptions.reducer';
import surveysReducer from './surveys';
import geoOptionsReducer from './geoOptions.reducer';
import surveyOptionsReducer from './surveyOptions.reducer';
import chartReducer from './chart.reducer';
import alertReducer from './alert.reducer';
import providerOptionsReducer from './providerOptions.reducer';
import reportsReducer from './reports.reducer';
import organizationOptionsReducer from './organizationOptions.reducer';
import bookingReducer from './booking.reducer';
import smsTemplateReducer from './smsTemplates.reducer';
import superAdminReducer from './superAdmin.reducer';
import productCategoryReducer from './productCategory.reducer';
import productsReducer from './products.reducer';
import activityLogsReducer from './activityLogs.reducer';

const rootReducer = combineReducers({
  common: commonReducer,
  provider: ProvidersReducer,
  user: UserReducer,
  email: TemplateReducer,
  organization: OrganizationReducer,
  service: ServiceReducer,
  image: ImageUploadReducer,
  picture: PictureUploadReducer,
  location: LocationReducer,
  manageCalendar: manageCalendarReducer,
  tmpServices: tmpServicesReducer,
  eventServices:eventServicesReducer,
  serviceCategory: serviceCategoryReducer,
  businessCategory: businessCategoryReducer,
  tmpServiceDetail: tmpServiceDetailReducer,
  availabilitySlots: availabilitySlotsReducer,
  scheduleReports: scheduleReportsReducer,
  customerService: customerServiceReducer,
  surveys: surveysReducer,
  chart: chartReducer,
  alert: alertReducer,
  reports: reportsReducer,
  booking: bookingReducer,
  options: combineReducers({
    geo: geoOptionsReducer,
    timezone: timezoneOptionsReducer,
    service: serviceOptionsReducer,
    counter: counterOptionsReducer,
    survey: surveyOptionsReducer,
    provider: providerOptionsReducer,
    organization: organizationOptionsReducer
  }),
  smsTemplates:smsTemplateReducer,
  superAdmin: superAdminReducer,
  activityLogs:activityLogsReducer,
  productCategory:productCategoryReducer, 
  products:productsReducer
});

export default rootReducer;
