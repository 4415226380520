import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Formik } from 'formik'
import * as Yup from 'yup'

import {
  Dialog,
  DialogContent, DialogTitle,
  DialogActions,
  TextField,
  Grid,
} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
//import Slide from '@material-ui/core/Slide'
import Close from '@material-ui/icons/Close'

import Button from 'components/CustomButtons/Button'
import CustomInput from 'components/CustomInput/CustomInput'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle';


import {
  requestConfirmAccountOtp,
  verifyUserCode,
} from 'actions/auth'

const styles = theme =>({
  ...modalStyle(theme),
  overflowDisable: {
    'overflow-y': 'unset',
  },
  container: {
    'margin-top': '10px',
    'margin-bottom': '3px',
  },
  noWrap: {
    'flex-wrap': 'nowrap',
  }
})

function ConfirmAccount({
  modalState,
  classes,
  closeDialog,
  requestConfirmAccountOtpAction,
  confirmAccountOtp
}) {

  const [email, setEmail] = useState('')
  const [otpSent, setOtpSent] = useState(false)

  const closeModal = () => {
    setOtpSent(false)
    setEmail('')
    closeDialog()
  }

  const reqVerificationCode = async (values) => {
    setEmail(values.email)
    const result = await requestConfirmAccountOtpAction(values.email)

    if (result)
      setOtpSent(true)
  }

  const onChangeEmail = (e, setFieldValue)=>{
    setEmail(e.target.value)
    setFieldValue("email",e.target.value )
  }

  const validateOtp = async (values) => {
    const result = await confirmAccountOtp(email, values.code);
    if ( result )
      closeModal()
  }

  return (
    <>
      <Dialog
        open={modalState}
        aria-labelledby="form-dialog-title"
        maxWidth='xs'
      >
        <DialogTitle className={classes.modalHeader}>
          Confirm Account
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={closeModal}
          >
            <Close className={classes.modalClose} />
          </Button>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              email: email,
            }}
            validationSchema={
              Yup.object().shape({
                email: Yup.string()
                  .email('Invalid email address')
                  .required('Enter the registered email'),
              })
            }
            onSubmit={reqVerificationCode}
          >
            {
              (formikProps) => {
                const {
                  handleSubmit,
                  values,
                  handleChange,
                  setFieldValue,
                  errors,
                } = formikProps
                return (
                  <form
                    onSubmit={handleSubmit}
                  >
                    <GridContainer alignItems="center">
                      <GridItem>
                        <CustomInput
                          id="email"
                          labelText='Email'
                          error={errors.email}
                          helperText={errors.email}
                          inputProps={{
                            placeholder: 'Enter your registered email address',
                            name: "email",
                            type: 'text',
                            maxLength: '50',
                            onChange: (e)=>onChangeEmail(e,setFieldValue),
                            value: values.email,
                          }}
                          formControlProps={{ fullWidth: true }}
                        />
                      </GridItem>
                      <GridItem>
                        <Button
                          color='rose'
                          type='submit'
                          size='sm'
                        >
                          send otp
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </form>
                )
              }
            }
          </Formik>
          <Formik
            initialValues={{
              code: '',
            }}
            validationSchema={
              Yup.object().shape({
                code: Yup.string()
                  .required('Enter the code received'),
              })
            }
            onSubmit={validateOtp}
          >
            {
              (formikProps) => {
                const {
                  handleSubmit,
                  values,
                  handleChange,
                  errors,
                } = formikProps
                return (
                  <form
                    onSubmit={handleSubmit}
                  >
                    <GridContainer alignItems="center">
                      <GridItem>
                        <CustomInput
                          id="code"
                          labelText='Verification Code'
                          error={errors.code}
                          helperText={errors.code}
                          inputProps={{
                            placeholder: 'Enter received code',
                            name: "code",
                            type: 'text',
                            maxLength: 50,
                            onChange: handleChange,
                            value: values.code
                          }}
                          formControlProps={{ fullWidth: true }}
                        />
                      </GridItem>
                      <GridItem>
                        <Button
                          color='rose'
                          type='submit'
                          size='sm'
                        >
                          Submit
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </form>
                )
              }
            }
          </Formik>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch) => ({
  requestConfirmAccountOtpAction: (email) => dispatch(requestConfirmAccountOtp(email)),
  confirmAccountOtp: (email, code, history) => dispatch(verifyUserCode('', email, code, history)),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)
  (withStyles(styles)(ConfirmAccount))

