import { 
  FETCH_PRODUCTS_LOADING,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_ERROR,
  FETCH_PRODUCT_DETAILS,
} from "actions/products";


const initialState = {
  loading:false,
  list:[],
  fetchedProduct:null,
  error:null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    case FETCH_PRODUCTS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case FETCH_PRODUCT_DETAILS:
      return {
        ...state,
        fetchedProduct: action.payload,
      };
    case FETCH_PRODUCTS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;