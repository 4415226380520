export const tmp_service = {

  FETCH_TMP_SERVICES_LOADING: 'FETCH_TMP_SERVICES_LOADING',
  FETCH_TMP_SERVICES_SUCCESS: 'FETCH_TMP_SERVICES_SUCCESS',

  DEL_TMP_SERVICE_LOADING: 'DEL_TMP_SERVICE_LOADING',
  DEL_TMP_SERVICE_SUCCESS: 'DEL_TMP_SERVICE_SUCCESS',

  SET_TMP_SERVICE: 'SET_TMP_SERVICE',

  SET_SCHEDULE_REPORT_DATA: 'SET_SCHEDULE_REPORT_DATA',
  SET_SCHEDULE_REPORT_LOADING: 'SET_SCHEDULE_REPORT_LOADING',
  SET_TRIGGER_EMAIL_LOADING: 'SET_TRIGGER_EMAIL_LOADING',
};
