import axios from 'axios';
import { location } from 'constants/Location.constants';
import { URL } from 'config/config';
import { fetchGeoLocationOptions } from 'actions/geoOptions'
import { showAlert } from 'actions/alert'
import get from 'lodash/get'

export const fetchLocations = () => {
  return dispatch => {
    dispatch({ type: location.FETCH_LOCATIONS_LOADING });
    axios.get(URL.GEO_LOCATIONS)
      .then(({ data }) => {
        dispatch({
          type: location.FETCH_LOCATIONS_SUCCESS,
          payload: data.objects
        });
      })
      .catch(err => {
        dispatch({
          type: location.FETCH_LOCATIONS_FAILURE,
          payload: err
        });
      });
  };
};

export const delLocation = id => {
  return dispatch => {
    dispatch({ type: location.DEL_LOCATION_LOADING });
    axios.delete(`${URL.GEO_LOCATIONS}/${id}`)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: location.DEL_LOCATION_SUCCESS,
            payload: data.object,
          });
          dispatch(fetchLocations());
        } else {
          dispatch({
            type: location.DEL_LOCATION_FAILURE,
            payload: data
          });
        }
      });
  };
};

export const fetchLocation = id => {
  return dispatch => {
    dispatch({ type: location.FETCH_LOCATION_LOADING });
    axios.get(`${URL.GEO_LOCATIONS}/${id}`)
      .then(({ data }) => {
        dispatch({
          type: location.FETCH_LOCATION_SUCCESS,
          payload: data.object
        });
      })
      .catch(err => {
        dispatch({
          type: location.FETCH_LOCATION_FAILURE,
          payload: err
        });
      });
  };
};

export const createLocation = (values, history) => ( 
  async dispatch => {
    try {
      dispatch({ type: location.CREATE_LOCATION_LOADING })
      const { data } = await axios.post(URL.GEO_LOCATIONS, values)
      dispatch({
        type: location.CREATE_LOCATION_SUCCESS,
        payload: data.object
      })
      if (!!get(history,'location.state')) {
        const { prevPage, prevState } = history.location.state
        history.push(prevPage, {
          prevState: {
            ...prevState,
            addEventData: {
              ...prevState.addEventData,
              tmpService: {
                ...prevState.addEventData.tmpService,
                geoLocationId: data.object.id
              }
            }
          }
        })
      } else {
        // history.push('/location/list');
      }
      if(data?.success){
        dispatch(fetchGeoLocationOptions())
        dispatch(showAlert('success','Location created'))
      }else{
        if(data?.messsage)
          dispatch(showAlert('error',data?.messsage))
      }
      return data?.object
    }
    catch(err) {
      dispatch({
        type: location.CREATE_LOCATION_FAILURE,
        payload: err
      });
      return false
    }
  }
)

export const editLocation = (values, history) => {
  return async dispatch => {
    try{
      dispatch({ type: location.EDIT_LOCATION_LOADING });
      const {data} = await axios.put(URL.GEO_LOCATIONS, values)
        if(history)
          history.push('/location/list');
      return data?.object
    }catch(err){
        dispatch({
          type: location.EDIT_LOCATION_FAILURE,
          payload: err
        });
      };
  };
};
