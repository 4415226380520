import {
  SET_SURVEYS,
  SAVE_SURVEY,
  DELETE_SURVEY_BY_ID,
  RESET_SURVEY_STATUS,
  FETCH_SURVEY_ANSWERS_LIST,
  FETCH_SURVEY_LIST,
  SET_SURVEY_ANSWERS_LIST_LOADING,
  SET_SURVEY__LIST_LOADING,
  SET_SURVEY_TO_TEMPORARY_SERVICE,
  CLEAR_SURVEY_SET_TO_TEMPORARY_SERVICE,
  FILTERED_FEEDBACK_ANSWERS_LIST,
  SET_FEEDBACK_ANSWERS_LIST_LOADING
} from "actions/surveys";

const initState = {
  surveys: null,
  isSavedSurvey: null,
  isDeletedSurveyById: null,
  surveyList: [],
  surveyListLoading: false,
  surveyAnswers: [],
  surveyAnswersLoading: false,
  selectedScheduledServiceSurvey: {},
  filteredFeedbackAnswers: [],
  feedbackAnswersLoading: false,
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case SET_SURVEYS:
      return {
        ...state,
        surveys: action.payload,
      };
    case SAVE_SURVEY:
      return {
        ...state,
        isSavedSurvey: action.payload,
      };
    case RESET_SURVEY_STATUS:
      return {
        ...state,
        isSavedSurvey: null,
        isDeletedSurveyById: null,
      };
    case DELETE_SURVEY_BY_ID:
      return {
        ...state,
        isDeletedSurveyById: action.payload,
      };
    case FETCH_SURVEY_LIST:
      return {
        ...state,
        surveyList: action.payload,
      };
    case FETCH_SURVEY_ANSWERS_LIST:
      return {
        ...state,
        surveyAnswers: action.payload,
      };
    case SET_SURVEY__LIST_LOADING:
      return {
        ...state,
        surveyListLoading: action.payload,
      };
    case SET_SURVEY_ANSWERS_LIST_LOADING:
      return {
        ...state,
        surveyAnswersLoading: action.payload,
      };
    case FILTERED_FEEDBACK_ANSWERS_LIST:
      return {
        ...state,
        filteredFeedbackAnswers: action.payload,
      };
    case SET_FEEDBACK_ANSWERS_LIST_LOADING:
      return {
        ...state,
        feedbackAnswersLoading: action.payload,
      };
    case SET_SURVEY_TO_TEMPORARY_SERVICE:
      return {
        ...state,
        selectedScheduledServiceSurvey: action.payload,
      }
    case CLEAR_SURVEY_SET_TO_TEMPORARY_SERVICE:
      return {
        ...state,
        selectedScheduledServiceSurvey: {}
      }
    default:
      return state;
  }
};

export default reducer;
