
import axios from 'axios';
import { get } from 'lodash';
import { URL } from 'config/config';
import { showAlert } from './alert';

export const FETCH_PRODUCT_CATEGORIES_LOADING = 'FETCH_PRODUCT_CATEGORIES_LOADING';
export const FETCH_PRODUCT_CATEGORIES_SUCCESS = 'FETCH_PRODUCT_CATEGORIES_SUCCESS';
export const FETCH_PRODUCT_CATEGORIES_ERROR = 'FETCH_PRODUCT_CATEGORIES_ERROR';



const fetchProductCategoriesLoading = (value = true) => ({
    type: FETCH_PRODUCT_CATEGORIES_LOADING,
    payload: value
});
const fetchProductCategoriesSuccess = payload => ({
    type: FETCH_PRODUCT_CATEGORIES_SUCCESS,
    payload
});
const fetchProductCategoriesFailure = payload => ({
    type: FETCH_PRODUCT_CATEGORIES_ERROR,
    payload
});


export const fetchProductCategories = () => async dispatch => {
    dispatch(fetchProductCategoriesLoading(true));
    try {
        const response = await axios.get(URL.PRODUCT_CATEGORIES);
        if (response && response.data.success) {
            const data = get(response, 'data.objects', []);
            dispatch(fetchProductCategoriesSuccess(data));
            dispatch(fetchProductCategoriesLoading(false));
            return data
        }
    } catch (err) {
        dispatch(fetchProductCategoriesFailure(err));
        dispatch(fetchProductCategoriesLoading(false));
    };
}

export const createProductCategory = (payload) => async dispatch => {
    try {
        const response = await axios.post(URL.PRODUCT_CATEGORIES, payload);
        if (response && response.data.success) {
            const data = get(response, 'data.object', []);
            await dispatch(fetchProductCategories())
            return data
        } else if (response?.data?.message) {

            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}

export const editProductCategory = (payload, refetch=true ) => async dispatch => {
    try {
        const response = await axios.put(URL.PRODUCT_CATEGORIES, payload);
        if (response && response.data.success) {
            const data = get(response, 'data.object', []);
            if(refetch){
                await dispatch(fetchProductCategories())
            }
            return data
        } else if (response?.data?.message) {
            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}

export const deleteProductCategory = (categoryId) => async dispatch => {
    try {
        const response = await axios.delete(URL.PRODUCT_CATEGORIES + `/${categoryId}`);
        if (response && response.data.success) {
            dispatch(fetchProductCategories())
            return true
        } else if (response?.data?.message) {
            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}

export const saveProductCategoryOrder = (payload) => async dispatch => {
    try {
        const response = await axios.post(URL.PRODUCT_CATEGORIES_ORDER, payload );
        if (response && response.data.success) {
            dispatch(fetchProductCategories())
            return true
        } else if (response?.data?.message) {
            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}