import { counter_options } from '../constants/CounterOptions.constants';

const initialState = {
  isFetchCounterOptionsSuccess: false,
  counterOptions: [],
  isLoading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case counter_options.FETCH_COUNTER_OPTIONS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case counter_options.FETCH_COUNTER_OPTIONS_SUCCESS:
      return {
        ...state,
        counterOptions: action.payload,
        isFetchCounterOptionsSuccess: true
      };
    default:
      return state;
  }
};

export default reducer;
