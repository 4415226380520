import axios from 'axios'
import store from 'index'
import { showAlert } from 'actions/alert'
import { checkTokens } from 'actions/auth'

axios.interceptors.request.use(
  async (request) => {
    const accessToken = await checkTokens() // ensure the tokens are upto date
    if (!!accessToken) {
      request.headers.common['Authorization'] = `Bearer ${accessToken}`
    }
    // Auth.currentSession either returns the current valid tokens or if they are expired refreshes them and returns the same
    return request
  }
)

axios.interceptors.response.use(
  resp => {
    if (resp.data.message) {
      if (!resp.data.success) {
        // temporary measure to suppress 'no survey found error message'
        if( 
          resp.data.message !== 'Oops! Survey not found' && 
          resp.data.message !== 'Credentials not available'
        )
          store.dispatch(showAlert('error', resp.data.message));
        
      }
    }
    return resp;
  },
  error => {
    if (error.response && error.response.data.message) {
      store.dispatch(showAlert('error', error.response.data.message));
    }
  }
);
