export const location = {
  FETCH_LOCATIONS_LOADING: 'FETCH_LOCATIONS_LOADING',
  FETCH_LOCATIONS_SUCCESS: 'FETCH_LOCATIONS_SUCCESS',
  FETCH_LOCATIONS_FAILURE: 'FETCH_LOCATIONS_FAILURE',

  FETCH_LOCATION_LOADING: 'FETCH_LOCATION_LOADING',
  FETCH_LOCATION_SUCCESS: 'FETCH_LOCATION_SUCCESS',
  FETCH_LOCATION_FAILURE: 'FETCH_LOCATION_FAILURE',

  CREATE_LOCATION_LOADING: 'CREATE_LOCATION_LOADING',
  CREATE_LOCATION_SUCCESS: 'CREATE_LOCATION_SUCCESS',
  CREATE_LOCATION_FAILURE: 'CREATE_LOCATION_FAILURE',

  EDIT_LOCATION_LOADING: 'EDIT_LOCATION_LOADING',
  EDIT_LOCATION_SUCCESS: 'EDIT_LOCATION_SUCCESS',
  EDIT_LOCATION_FAILURE: 'EDIT_LOCATION_FAILURE',

  DEL_LOCATION_LOADING: 'DEL_LOCATION_LOADING',
  DEL_LOCATION_SUCCESS: 'DEL_LOCATION_SUCCESS',
  DEL_LOCATION_FAILURE: 'DEL_LOCATION_FAILURE'
};
