import axios from 'axios';
import { get } from 'lodash';

import { URL } from 'config/config';
import { FETCH_GEO_OPTIONS } from 'constants/GeoOptions.constants';

const fetchGeoOptionsSuccess = geoOptions => ({
  type: FETCH_GEO_OPTIONS.SUCCESS,
  geoOptions
});

export const fetchGeoLocationOptions = () => async dispatch => {
  const response = await axios.get(URL.GEO_LOCATION_OPTIONS)
  if(response && response.data.success) {
    const data = get(response, 'data.objects', []);
    dispatch(fetchGeoOptionsSuccess(data));
    return data
  }
};
