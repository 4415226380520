export const handleResponse = (response, defaultResponse) => {
  if (response && response.data.success) {
    return response.data.objects || response.data.object || response.data;
  }
  return defaultResponse;
};

export const handleError = (response) => {
  if (response && response.data) {
    return response.data.message;
  }
  return 'Cannot connect to services';
};

export const handleRequest = async (requestFunc, args, defaultResponse) => {
  try {
    return [
      handleResponse(await requestFunc(...args), defaultResponse),
      null,
    ];
  } catch (e) {
    return [
      null,
      handleError(e.response),
    ];
  }
};

export const handleResponseBulk = response => (Array.isArray(response) ? response.map(item => item.data.object) : []);

export const callbackWrapper = async ({
  mainFunction,
  mainFunctionParameters=null,
  callbackFunction,
  callbackFunctionParameters=null
}) => {
  // the wrapper accepts only an object with mainFunctionParameters and callbackFunctionParameters
  // the callbackFunction will always have the the mainFunction's response as a named parameter
  const response = await mainFunction(mainFunctionParameters)
  if (typeof(callbackFunctionParameters) == Object) {
    callbackFunctionParameters.response = response
    callbackFunction(callbackFunctionParameters)
  }
  else
    callbackFunction({response})
}
