
import axios from 'axios';
import { get } from 'lodash';
import { URL } from 'config/config';
import { showAlert } from './alert';

export const FETCH_SMS_TEMPLATE_LOADING = 'FETCH_SMS_TEMPLATE_LOADING';
export const FETCH_SMS_TEMPLATE_SUCCESS = 'FETCH_SMS_TEMPLATE_SUCCESS';
export const FETCH_SMS_TEMPLATE_ERROR = 'FETCH_SMS_TEMPLATE_ERROR';
export const FETCH_SMS_EVENT_SUCCESS = 'FETCH_SMS_EVENT_SUCCESS';


const fetchSmsEventsSuccess = payload => ({
    type: FETCH_SMS_EVENT_SUCCESS,
    payload
});
const fetchSmsTemplateLoading = (value = true) => ({
    type: FETCH_SMS_TEMPLATE_LOADING,
    payload: value
});
const fetchSmsTemplateSuccess = payload => ({
    type: FETCH_SMS_TEMPLATE_SUCCESS,
    payload
});
const fetchSmsTemplateFailure = payload => ({
    type: FETCH_SMS_TEMPLATE_ERROR,
    payload
});


export const fetchSmsEvents = () => async dispatch => {
    const response = await axios.get(URL.SMS_EVENTS)
    if (response && response.data.success) {
        const data = get(response, 'data.objects', []);
        dispatch(fetchSmsEventsSuccess(data));
        return data
    }
};

export const fetchSmsTemplates = () => async dispatch => {
    dispatch(fetchSmsTemplateLoading(true));
    try {
        const response = await axios.get(URL.SMS_TEMPLATES);
        if (response && response.data.success) {
            const data = get(response, 'data.objects', []);
            dispatch(fetchSmsTemplateSuccess(data));
            dispatch(fetchSmsTemplateLoading(false));
            return data
        }
    } catch (err) {
        dispatch(fetchSmsTemplateFailure(err));
        dispatch(fetchSmsTemplateLoading(false));
    };
}

export const createSmsTemplate = (payload) => async dispatch => {
    try {
        const response = await axios.post(URL.SMS_TEMPLATES, payload);
        if (response && response.data.success) {
            const data = get(response, 'data.object', []);
            dispatch(fetchSmsTemplates())
            return data
        } else if (response?.data?.message) {

            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}

export const editSmsTemplate = (payload) => async dispatch => {
    try {
        const response = await axios.put(URL.SMS_TEMPLATES, payload);
        if (response && response.data.success) {
            const data = get(response, 'data.object', []);
            dispatch(fetchSmsTemplates())
            return data
        } else if (response?.data?.message) {
            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}

export const deleteSmsTemplate = (templateId) => async dispatch => {
    //dispatch(createSmsTemplateLoading());
    try {
        const response = await axios.delete(URL.SMS_TEMPLATES + `/${templateId}`);
        if (response && response.data.success) {
            dispatch(fetchSmsTemplates())
            return true
        } else if (response?.data?.message) {
            dispatch(showAlert('error', response?.data?.message))
        }
    } catch (err) {
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}