import { service_options } from '../constants/ServiceOptions.constants';

const initialState = {
  isFetchServiceOptionsSuccess: false,
  serviceOptions: [],
  isLoading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case service_options.FETCH_SERVICES_OPTIONS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case service_options.FETCH_SERVICES_OPTIONS_SUCCESS:
      return {
        ...state,
        serviceOptions: action.payload,
        isFetchServiceOptionsSuccess: true
      };
    default:
      return state;
  }
};

export default reducer;
