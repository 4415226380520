import Resizer from "react-image-file-resizer";

export const resizeImageFile = (file, config) =>
  new Promise((resolve) => {
    //console.log("file",file)
    const compressFormat = 
      file && file?.type?.includes("png") ?
      'PNG'
      :
      file && file?.type?.includes("webp") ?
      'WEBP'
      :
      "JPEG"
    Resizer.imageFileResizer(
      file,
      config?.maxWidth?config.maxWidth:1080,
      config?.maxHeight?config.maxHeight:1080,
      config?.compressFormat?config.compressFormat:compressFormat,
      config?.quality?config.quality:100,
      config?.rotation?config.rotation:0,
      (uri) => {
        resolve(uri);
      },
      config?.outputType?config.outputType:'blob',
    );
  });