import { 
    FETCH_PRODUCT_CATEGORIES_LOADING,
    FETCH_PRODUCT_CATEGORIES_SUCCESS,
    FETCH_PRODUCT_CATEGORIES_ERROR,
} from "actions/productCategories";


const initialState = {
    loading:false,
    list:[],
    error:null
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_PRODUCT_CATEGORIES_SUCCESS:
        return {
          ...state,
          list: action.payload,
        };
      case FETCH_PRODUCT_CATEGORIES_LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case FETCH_PRODUCT_CATEGORIES_ERROR:
        return {
          ...state,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default reducer;