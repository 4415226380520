import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { requestConfirmAccountOtp } from '../../actions/auth';
import { showAlert } from 'actions/alert';

class ResendCodeButton extends PureComponent {
  static propTypes = {
    email: PropTypes.string.isRequired,
    cbAfterResend: PropTypes.func.isRequired,
    countDownResendCode: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    requestConfirmAccountOtp: PropTypes.func.isRequired
  };

  handleResendVerificationCode = async e => {
    e.preventDefault();
    const { countDownResendCode } = this.props;

    if (countDownResendCode === 0) {
      const { email, cbAfterResend, requestConfirmAccountOtp } = this.props;
      requestConfirmAccountOtp( email , response =>{
        if(response){
          cbAfterResend();
        }
      });
    }
  };

  render() {
    const { countDownResendCode, classes } = this.props;
    return (
      <div>
        <Link
          to="#"
          onClick={this.handleResendVerificationCode}
          className={classes.resendCode}
          disabled={countDownResendCode > 0}
        >
          Resend code
        </Link>
        {countDownResendCode > 0 && <span>{countDownResendCode}</span>}
      </div>
    );
  }
}

export default connect(
  null,
  { requestConfirmAccountOtp, showAlert }
)(ResendCodeButton);
