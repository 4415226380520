let instance = null;

export default class IconsStorage {

	icons;
	requestWaitingForIcons;

	constructor() {
		if (instance) {
			return instance;
		}

		this.requestWaitingForIcons = [];
		instance = this;
	}

	async getIcons() {
		if (this.icons) {
			return this.icons
		}

		this.isLoadingIcons = true;

		const response =  await fetch('https://raw.githubusercontent.com/google/material-design-icons/3.0.2/iconfont/codepoints')
      //await console.log(response)
			const data = await response.text()
      //await console.log(data)
			const namesAndCodes = data.split('\n')
			const icons = namesAndCodes.map(nameAndCode => {
			const parts = nameAndCode.split(' ');
				return {
					name: parts[0],
					code: parts[1]
				};
			})
      this.icons = icons;
      this.isLoadingIcons = false;
      if (this.requestWaitingForIcons.length > 0) {
        this.requestWaitingForIcons.map(awaitingPromise => {
          awaitingPromise.resolve(icons);
        });
      }

      return icons;
	}

}

