import { useEffect, useState, useRef } from 'react'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  LinearProgress,
  Grid,
} from '@material-ui/core'
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button"
import IconsStorage from './IconsStorage'
import CustomInput from 'components/CustomInput/CustomInput'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'


const styles = {
  backgroundBox: {
    backgroundColor: 'rgb(224, 224, 224)',
    borderRadius: 2,
    height: 120,
    opacity: 0,
    position: 'absolute',
    top: 0,
    transitionProperty: 'opacity',
    transitionDuration: '200ms',
    transitionTimingFunction: 'ease-out',
    width: 112,
    marginLeft: 'calc(112px / 2)',
    transform: 'translateX(-50%)',
    transitionDelay: 'initial'
  },
  selectedBackgroundBox: {
    opacity: 1
  },
  iconsGrid: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  iconsItem: {
    textAlign: 'center',
    width: 100,
    flexGrow: 1,
    marginBottom: 10,
    position: 'relative',
    height: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    cursor: 'pointer'
  },
  iconsItemCaption: {
    textTransform: 'uppercase',
    fontSize: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    zIndex: 2,
    maxWidth: 100
  },
  header: {
    input: {
      flex: 1,
      border: 'none',
      padding: 15,
      fontSize: 17,
      margin: '0 40',
      ':focus': {
        outline: 'none'
      }
    },
    icons: {},
    title: {
      margin: 0,
      paddingLeft: 24,
      paddingTop: 0,
      paddingRight: 24,
      textTransform: 'uppercase'
    },
    search: {
      boxShadow: 'rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px, rgba(0, 0, 0, 0.2) 0px 2px 4px -1px',
      background: '#fff',
    },
    closeIcon: {
      cursor: 'pointer',
      color: '#555'
    },
  }
}

const useStyles = makeStyles(theme => ({ ...styles, ...modalStyle(theme) }))

function IconPicker({
  onPick,
  cancelLabel = 'Cancel',
  pickLabel = 'Pick',
  label = 'Pick icon',
  modalTitle = 'Material icon picker',
  buttonSize = "md"
}) {

  const [iconsStorage, setIconsStorage] = useState([])

  useEffect(
    () => {
      async function getIcons() {
        const iconFetcher = new IconsStorage
        const fetchedIcons = await iconFetcher.getIcons()
        showIcons(fetchedIcons)
      }
      getIcons()
    },
    [iconsStorage]
  )



  const [allIcons, setAllIcons] = useState([])

  const [displayIcons, setDisplayIcons] = useState([])

  const showIcons = (icons) => {
    setAllIcons(icons)
    setDisplayIcons(icons)
  }

  const [icon, setIcon] = useState(null)

  const [pickerDialogOpen, setPickerDialogOpen] = useState(false)

  const handleOpen = () => {
    setPickerDialogOpen(true)
  }

  const [searchText, setSearchText] = useState('')

  const handleClose = () => {
    setPickerDialogOpen(false)
    if (searchText) {
      setDisplayIcons(allIcons)
      setSearchText('');
    }

  }

  const [selected, setSelected] = useState(false)

  const pickAndClose = () => {
    onPick(selected)
    handleClose()
  }

  const select = (icon) => {
    setSelected(icon)
  }

  const filterList = (event) => {

    //console.log(event.target.value)
    setSearchText(event.target.value)
    if (event.target.value.toLowerCase().length === 0) {
      clearSearch()
    } else {
      let updatedList = allIcons;
      const searchWord = event.target.value.toLowerCase().trim();
      //console.log(updatedList);
      updatedList = updatedList.filter( (item) => item.name.replace("_"," ").includes(searchWord))
      setDisplayIcons(updatedList)
    }
  }

  const searchInput = useRef(null)

  const clearSearch = () => {
    setSearchText('');
    setDisplayIcons(allIcons)
  }

  const classes = useStyles()

  const actions = [
    <Button
      key="1"
      onClick={handleClose}
    >
      {cancelLabel}
    </Button>
    ,
    <Button
      key="2"
      color='primary'
      disabled={!selected}
      onClick={pickAndClose}
    >
      {selected ?
        <Icon className="material-icons">{selected.name}</Icon> : null}
      {pickLabel}
    </Button>
    ,
  ]

  const displayedIcons = displayIcons.map(
    (icon, index) => {
      return (
        <Button
          key={index}
          className={classes.iconsItem}
          color={selected && selected.name === icon.name ? "primary" : "transparent"}
          onClick={
            () => select(icon)
          }
        >
          <div>
            <Icon
              style={{
                color: selected && selected.name === icon.name ? '#fff' : 'rgb(117, 117, 117)',
                fontSize: 30,
                marginTop: 5,
                marginBottom: 5
              }}
              className="material-icons"
            >
              {icon.name}
            </Icon>
            <div className={classes.iconsItemCaption} >
              {icon.name.split('_').join(' ')}
            </div>
          </div>

        </Button>
      )
    }
  )

  return (
    <div>
      <Button
        onClick={handleOpen}
        color='primary'
        size={buttonSize}
      >
        {label}
      </Button>

      <Dialog
        open={pickerDialogOpen}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle className={classes.modalHeader}>
          {modalTitle}
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={handleClose}
          >
            <Close className={classes.modalClose} />
          </Button>
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} md={12}>
              <CustomInput
                inputProps={{
                  placeholder: "Search",
                  name: "Search",
                  type: 'text',
                  maxLength: '50',
                  onChange: filterList,
                  value: searchText,
                  startAdornment: <Icon
                    className="material-icons"
                    style={{ color: '#ddd' }}
                  >
                    search
                  </Icon>,
                  endAdornment: searchText ?
                    <Button
                      justIcon
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      color="transparent"
                      onClick={clearSearch}
                    >
                      <Close className={classes.modalClose} />
                    </Button>
                    :
                    null

                }}
                formControlProps={{ fullWidth: true }}
              />

            </GridItem>
          </GridContainer>
          {
            displayedIcons.length > 0
              ?
              <GridContainer>
                {displayedIcons}
              </GridContainer>
              :
              <div
                className={classes.iconsGrid}
              >
                No results
              </div>

          }
        </DialogContent>
        <DialogActions>
          {actions}
        </DialogActions>

      </Dialog>
    </div>

  )
}

export default IconPicker

