import React, { createRef, Suspense } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import withStyles from '@material-ui/core/styles/withStyles';
import { dashboardRoutes, otherRoutes, profileRouteComponent, managementRoutes, superAdminRoutes, operationRoutes, tvDisplayRoutes } from 'routes/dashboard.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import Header from 'components/Header/Header.jsx';
import appStyle from 'assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx';
import image from 'assets/img/sidebar-2.jpg';
import logo from 'assets/img/favicon.png';
import withAuth from "hoc/withAuth";
import { refreshTokens, fetchUser } from 'actions/auth.js';
import axios from 'axios';
import { userStatus } from 'constants.js';
import {
	SettingsOutlined,
	AccountCircleOutlined,
} from '@material-ui/icons';

let ps;
class Dashboard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.mainPanelRef = createRef();
    this.state = {
      mobileOpen: false,
      miniActive: false
    };
  }

  async componentDidMount() {
    const { history, userDetail } = this.props;
    const userSub = userDetail.id || localStorage.getItem('userSub');

    if (userDetail.userStatus !== userStatus.changePassword) {
      await this.props.refreshTokens()
    }

    if (userSub) {
      this.props.fetchUser(userSub, history)
    }

    if (this.mainPanelRef.current && navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.mainPanelRef.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      //document.body.style.overflow = 'hidden';
    }
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.mainPanelRef.current.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  componentWillUnmount() {
    if (ps && navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }

  getRoute() {
    return this.props.location.pathname !== '/maps/full-screen-maps';
  }

  handleDrawerToggle = () => {
    this.setState(oldState => ({ mobileOpen: !oldState.mobileOpen }));
  };

  sidebarMinimize = () => {
    this.setState(oldState => ({ miniActive: !oldState.miniActive }));
  };

  render() {
    const { classes, ...rest } = this.props;
    const mainPanel = `${classes.mainPanel} ${cx({
      [classes.mainPanelSidebarMini]: this.state.miniActive,
      [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1
    })}`;

    const managementCollapse = {
      collapse: true,
      name: "Management",
      rtlName: "Management",
      icon: SettingsOutlined,
      state: "managementCollapse",
      views: [
        ...managementRoutes,
      ]
    }
    const operationsCollapse = {
      collapse: true,
      name: "Operations",
      rtlName: "Operations",
      icon: AccountCircleOutlined ,
      state: "operationsCollapse",
      views: [
        ...operationRoutes,
      ]
    }

    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={[...dashboardRoutes, managementCollapse, operationsCollapse, ...tvDisplayRoutes]}
          logoText="QueZone"
          logo={logo}
          image={image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color="blue"
          bgColor="black"
          miniActive={this.state.miniActive}
          {...rest}
        />
        <div className={mainPanel} ref={this.mainPanelRef}>
          <Header
            sidebarMinimize={this.sidebarMinimize}
            miniActive={this.state.miniActive}
            routes={[...dashboardRoutes, ...managementRoutes, ...superAdminRoutes, ...operationRoutes, profileRouteComponent]}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          <div className={classes.content}>
            <div className={classes.container}>
              <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                  <Route
                    path={profileRouteComponent.path}
                    component={withAuth(profileRouteComponent.component, rest.userDetail)}
                    key={profileRouteComponent.path}
                  />
                  {!!axios.defaults.headers.common['Authorization'] &&
                    [
                      ...otherRoutes,
                      ...managementRoutes,
                      ...superAdminRoutes,
                      ...operationRoutes,
                      ...dashboardRoutes,
                    ].map((prop) => {
                      if (prop.redirect) return <Redirect from={prop.path} to={prop.pathTo} key={prop.path} />;
                      return <Route path={prop.path} component={withAuth(prop.component, rest.userDetail)} key={prop.path} />;
                    })}
                </Switch>
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  userDetail: PropTypes.object.isRequired,
  refreshTokens: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  userDetail: state.user.userDetail,
});

export default withStyles(appStyle)(connect(mapStateToProps, { refreshTokens, fetchUser })(Dashboard));
