import React from 'react';
import ScheduledServiceCreate from "./WizardSteps/ScheduledServiceCreate";
import Button from 'components/CustomButtons/Button';
import WizardAccordion from 'components/Accordion/WizardAccordion';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

class WizardScheduledServices extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			serviceIndexes: [1],
			error: ''
		}
		this.scheduledServiceInstances = [];
		this.scheduledServiceResponses = [];
	}

	removeScheduledService(serviceIndex) {
		const indexesArray = this.state.serviceIndexes;
		this.scheduledServiceInstances = this.scheduledServiceInstances.filter((arr, index) => index !== serviceIndex);
		let newIndexArray = indexesArray.filter(arr => arr !== serviceIndex);
		this.setState({ serviceIndexes: newIndexArray });
		console.log("this.scheduledServiceInstances >>", this.scheduledServiceInstances)
	}

	addScheduledService() {
		const indexesArray = this.state.serviceIndexes;
		const len = indexesArray.length;
		let nextIndex = 1;
		if (len > 0) {
			nextIndex = indexesArray[len - 1] + 1;
		}
		indexesArray.push(nextIndex);
		this.setState({ serviceIndexes: indexesArray });
		console.log("this.scheduledServiceInstances >>", this.scheduledServiceInstances)
	}


	wizardSubmit = async (allStates) => {
		console.log("Scheduled Services wizard submit");
		this.setState({ error: "" })
		let scheduledServicePayloadArray = []
		for (const scheduledServiceInstance of this.scheduledServiceInstances) {
			if (scheduledServiceInstance?.wizardSubmit) {
				const payload = await scheduledServiceInstance.wizardSubmit(allStates)
				if (payload) {
					scheduledServicePayloadArray.push(payload);


				} else {
					return false
				}
			}
		}
		let updatedStates = {
			...allStates,
			scheduledServicePayloadArray: scheduledServicePayloadArray
		}
		return updatedStates;
	}

	render() {
		this.scheduledServiceInstances = [];
		const collapsesValue = this.state.serviceIndexes.map((tserviceIndex, index) => {
			return {
				title: `Service Schedule ${tserviceIndex}`,
				content: (
					<GridContainer style={{ border: "1px solid rgb(226, 220, 220)" }}>
						<GridItem md={12}>
							<ScheduledServiceCreate isWizard={true} ref={(node) => {
								if (node != null) {
									this.scheduledServiceInstances[tserviceIndex] = node;
								}
							}}  allStates={this.props.allStates}/>
							{tserviceIndex > 1 && ((index + 1) == this.state.serviceIndexes.length) && <Button size="sm" color="rose" onClick={() => { this.removeScheduledService(tserviceIndex) }}>
								Remove Service
							</Button>}
						</GridItem>
					</GridContainer>
				)
			}
		});
		return (
			<div>
				<WizardAccordion
					collapses={collapsesValue}
				/>
				<GridContainer justifyContent={"flex-start"}>
					<GridItem>
						{<Button size="sm" color="rose" onClick={() => { this.addScheduledService() }}>
							Add Scheduled Service
						</Button>}
					</GridItem>
				</GridContainer>
				<div style={{ textAlign: 'center', color: 'red' }}>{this.state.error}</div>
			</div>
		);
	}
}
export default WizardScheduledServices;