import axios from 'axios';
import { get } from 'lodash';
import { URL } from 'config/config';
import { showAlert } from './alert';


export const SET_ACTIVITY_LOGS = 'SET_ACTIVITY_LOGS';
export const SET_ACTIVITY_LOGS_LOADING = 'SET_ACTIVITY_LOGS_LOADING';


const setActivityLogs = payload => ({
    type: SET_ACTIVITY_LOGS,
    payload
});
const setActivityLogsLoading = (value = true) => ({
    type: SET_ACTIVITY_LOGS_LOADING,
    payload: value
});

export const fetchActivityLogs = (pagination = '') => async dispatch => {
    try {
        dispatch(setActivityLogsLoading(true));
        const response = await axios.get(`${URL.ACTIVITY_LOGS}${pagination ? ('?' + pagination) : ''}`)
        if (response && response.data.success) {
            dispatch(setActivityLogs(response.data.objects));
            dispatch(setActivityLogsLoading(false));
            return response?.data
        }
        dispatch(setActivityLogsLoading(false));
    } catch (err) {
        dispatch(setActivityLogsLoading(false));
        if (err?.message) {
            dispatch(showAlert('error', err?.message))
        }
    };
}