import React from 'react'
import {
  Dialog,
  DialogContent, DialogTitle,
  DialogActions,
  TextField,
  Grid,
} from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import withStyles from '@material-ui/core/styles/withStyles';

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Formik } from 'formik'
import * as Yup from 'yup'

import Button from 'components/CustomButtons/Button';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle';

import { requestForgotPasswordOtp, changeForgotPassword } from 'actions/auth'

const styles = theme => ({
  ...modalStyle(theme),
  overflowDisable: {
    'overflow-y': 'unset',
  },
  container: {
    'margin-top': '10px',
    'margin-bottom': '3px',
  },
  noWrap: {
    'flex-wrap': 'nowrap',
  }
})

class ForgotPasswordPage extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      code: '',
      newPassword: '',
      confirmPassword: '',
      errorMessage: '',
      email: '',
    };

    this.changePasswordButton = React.createRef()
  }

  ReqVerificationCode = (values) => {
    this.setState({
      email: values.email
    })
    this.props.requestForgotPasswordOtp(values);
  }

  handleChangePassword = async (values) => {
    const { code, confirmPassword, newPassword, } = values
    const { email } = this.state

    if (code === '') {
      this.setState({ errorMessage: 'Verification code is not set!' });
      return;
    }
    if (newPassword === '') {
      this.setState({ errorMessage: 'New Password is not set!' });
      return;
    }
    if (confirmPassword === '') {
      this.setState({ errorMessage: 'Confirmed Password is not set!' });
      return;
    }
    if (newPassword !== confirmPassword) {
      this.setState({ errorMessage: 'New Password and Confirmed Password does not match!' });
      return;
    }
    const { history } = this.props;
    const changePasswordData = {
      code,
      newPassword,
      email
    };
    const result = await this.props.changeForgotPassword(changePasswordData, history);
    if(result)
      this.props.closeDialog();
  };

  clickSaveButton = () => {
    this.changePasswordButton.current.click()
  }

  render() {
    const {
      classes,
      closeDialog,
      open,
      otpRequestSent,
    } = this.props;
    return (
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        maxWidth='xs'
      >
        <DialogTitle className={classes.modalHeader}
          id="form-dialog-title"
        >
          Forgot Password
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={closeDialog}
          >
            <Close className={classes.modalClose} />
          </Button>
        </DialogTitle>
        <DialogContent
          className={classes.overflowDisable}
        >
          <Formik
            initialValues={{
              email: this.state.email,
            }}
            validationSchema={
              Yup.object().shape({
                email: Yup.string()
                  .email('Invalid email address')
                  .required('Enter the registered email'),
              })
            }
            onSubmit={(values) => this.ReqVerificationCode(values)}
          >
            {
              (formikProps) => {
                const {
                  handleSubmit,
                  values,
                  handleChange,
                  setFieldValue,
                  errors,
                } = formikProps
                return (
                  <form
                    onSubmit={handleSubmit}
                  >
                    <GridContainer alignItems="center">
                      <GridItem>
                        <CustomInput
                          id='Email'
                          labelText='Email'
                          error={errors.email}
                          helperText={errors.email}
                          inputProps={{
                            placeholder: 'Enter your email address',
                            name: 'email',
                            type: 'text',
                            onChange: (e) => {
                              this.setState({ email: e.target.value })
                              setFieldValue("email", e.target.value)
                            },
                            value: values.email,
                            maxLength: 50,
                          }}
                          formControlProps={{ fullWidth: true }}
                        />
                      </GridItem>
                      <GridItem>
                        <Button
                          color='rose'
                          type='submit'
                          size='sm'
                        >
                          {
                            !(otpRequestSent) ? (
                              'Send Code'
                            ) : (
                              'Re-send Code'
                            )
                          }
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </form>
                )
              }
            }
          </Formik>

          <Formik
            initialValues={{
              code: '',
              newPassword: '',
              confirmPassword: ''
            }}
            validationSchema={
              Yup.object().shape({
                code: Yup.string()
                  .required('Enter the code received'),
                newPassword: Yup.string()
                  .required('Please enter a password')
                  .matches(/^.*[a-z].*$/, 'Must contain atleast one lowercase alphabet')
                  .matches(/^.*[A-Z].*$/, 'Must contain atleast one uppercase alphabet')
                  .matches(/^.*[0-9].*$/, 'Must contain atleast one number')
                  .matches(/^.*[!@#$%^&*_\-+=`~].*$/, 'Must contain a special character !@#$`...')
                  .matches(/^.{8,}$/, 'Must have atleast 8 characters'),
                confirmPassword: Yup.string()
                  .required('Please re-enter your password')
                  .oneOf([Yup.ref('newPassword'), null], "The passwords entered doesn't match"),
              })
            }
            onSubmit={this.handleChangePassword}
          >
            {
              (formikProps) => {
                const {
                  handleSubmit,
                  values,
                  handleChange,
                  errors,
                } = formikProps
                return (
                  <form
                    autoComplete="email"
                    onSubmit={handleSubmit}
                  >
                    <GridContainer>
                      <GridItem xs={12} md={12}>
                        <CustomInput
                          id='code'
                          labelText='Verification Code'
                          error={errors.code}
                          helperText={errors.code}
                          inputProps={{
                            placeholder: 'Enter received code',
                            name: "code",
                            type: 'text',
                            autoComplete: 'one-time-code',
                            onChange: handleChange,
                            value: values.code,
                            maxLength: 15,
                          }}
                          formControlProps={{ fullWidth: true }}
                        />
                      </GridItem>
                      <GridItem xs={12} md={12}>
                        <CustomInput
                          id="newPassword"
                          labelText='New password'
                          error={errors.newPassword}
                          helperText={errors.newPassword}
                          inputProps={{
                            placeholder: 'Enter your new password',
                            name: 'newPassword',
                            type: 'password',
                            autoComplete: 'new-password',
                            onChange: handleChange,
                            value: values.newPassword,
                            maxLength: 50,
                          }}
                          formControlProps={{ fullWidth: true }}
                        />
                      </GridItem>
                      <GridItem xs={12} md={12}>
                        <CustomInput
                          id="confirmPassword"
                          labelText='Confirm New Password'
                          error={errors.confirmPassword}
                          helperText={errors.confirmPassword}
                          inputProps={{
                            placeholder: 'Re-enter the new password',
                            name: 'confirmPassword',
                            type: 'password',
                            autoComplete: 'new-password',
                            onChange: handleChange,
                            value: values.confirmPassword,
                            maxLength: 50,
                          }}
                          formControlProps={{ fullWidth: true }}
                        />
                      </GridItem>
                      <GridItem>
                        <button
                          hidden
                          type='submit'
                          ref={this.changePasswordButton}
                        >
                          Save
                        </button>
                      </GridItem>
                    </GridContainer>
                  </form>
                )
              }
            }
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeDialog}
          >
            Close
          </Button>
          <Button
            //disabled={!(otpRequestSent)}
            onClick={this.clickSaveButton}
            color="rose"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

ForgotPasswordPage.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  email: PropTypes.string,
  open: PropTypes.bool.isRequired,
  actionAfterSubmit: PropTypes.func,
  closeDialog: PropTypes.func.isRequired,
};

ForgotPasswordPage.defaultProps = {
  email: undefined,
  actionAfterSubmit: undefined
};

const mapStateToProps = state => {
  return {
    verifyDetail: state.user.verifyDetail,
    verifyLoading: state.user.verifyLoading,
    resetPasswordError: state.user.resetPasswordError,
    changePasswordRsp: state.user.changePasswordRsp,
    resetPasswordRsp: state.user.resetPasswordRsp,
    resetPasswordLoading: state.user.resetPasswordLoading,
    otpRequestSent: state.user.otpRequestSent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeForgotPassword: (changePasswordData, history) => dispatch(changeForgotPassword(changePasswordData, history)),
    requestForgotPasswordOtp: (email) => dispatch(requestForgotPasswordOtp(email)),
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(withStyles(styles)(ForgotPasswordPage))
