import React, { Component } from 'react';
import { Dialog, DialogContent} from '@material-ui/core';
import { BeatLoader } from "react-spinners";
import { roseColor } from 'assets/jss/material-dashboard-pro-react';

class LoadingModal extends Component {
  render() {
    let {
        openModal
    } = this.props;

    return (
        <Dialog
          aria-labelledby="custom-modal-label"
          aria-describedby="custom-modal-description"
          open={openModal}
          disableEscapeKeyDown
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
        >
            <DialogContent>
                <BeatLoader 
                    sizeUnit={"px"}
                    size={22}
                    color={roseColor[0]}
                />
            </DialogContent>
        </Dialog>
      );
    }
}

export default LoadingModal;