import { PersonAdd, Fingerprint } from '@material-ui/icons';
import LoginPage from 'views/Auth/LoginPage.js';
import RegisterPage from 'views/Auth/RegisterPage.js';

const authRoutes = [
  {
    path: '/login',
    name: 'Login Page',
    short: 'Login',
    mini: 'LP',
    icon: Fingerprint,
    component: LoginPage
  },
  {
    path: '/register',
    name: 'Register Page',
    short: 'Register',
    mini: 'RP',
    icon: PersonAdd,
    component: RegisterPage
  }
];

export default authRoutes;
