import { customer_service } from '../constants/CustomerService.constants';

const initialState = {
  isLoading: false,
  isVerifyBookingCodeSuccess: false,
  verifyData: {},

  isUpdateStatusSuccess: false,
  updateData: {},

  isBoardLoading: false,
  boardData: {},

  isVerifyMobileDataSuccess: false,
  verifyMobileData: {},
  blackListedCustomers:[],
  blackListedCustomersLoading:false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case customer_service.VERIFY_BOOKING_CODE_SUCCESS:
      return {
        ...state,
        verifyData: action.payload,
        isVerifyBookingCodeSuccess: true
      };
    case customer_service.VERIFY_MOBILE_DATA_SUCCESS:
      return {
        ...state,
        verifyMobileData: action.payload,
        isVerifyMobileDataSuccess: true,
      };
    case customer_service.VERIFY_BOOKING_CODE_FAILURE:
      return {
        ...state,
        verifyData: {},
        isVerifyBookingCodeSuccess: false
      }
    case customer_service.VERIFY_MOBILE_DATA_FAILURE:
      return {
        ...state,
        verifyMobileData: {},
        isVerifyMobileDataSuccess: false,
      };
    case customer_service.VERIFY_BOOKING_CODE_LOADING:
      return { ...state, isLoading: action.payload };
    case customer_service.VERIFY_MOBILE_DATA_LOADING:
      return { ...state, isLoading: action.payload };
    case customer_service.UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        isUpdateStatusSuccess: true,
        updateData: action.payload,
        verifyData: action.payload,
        isVerifyBookingCodeSuccess: false
      };
    case customer_service.FETCH_FLOW_BOARD_SUCCESS:
      return {
        ...state,
        boardData: action.payload,
      };
    case customer_service.FETCH_FLOW_BOARD_LOADING:
      return { ...state, isBoardLoading: action.payload };
    case customer_service.BLACKLISTED_CUSTOMERS_LOADING:
        return { ...state, blackListedCustomersLoading: action.payload };
    case customer_service.BLACKLISTED_CUSTOMERS:
      return { ...state, blackListedCustomers: action.payload };

    default:
      return state;
  }
};

export default reducer;
