import { booking } from 'constants/Booking.constants';

const initialState = {
  isLoading: false,
  bookingDetails: {}
  
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case booking.CREATE_BOOKING_LOADING:
      return { ...state, isLoading: action.payload }
    case booking.CREATE_BOOKING_SUCCESS:
      return { ...state, bookingDetails: action.payload }
    case booking.CLEAR_BOOKING: {
      return {
        ...state, 
        bookingDetails : {},
      }
    }
    default:
      return state
  }
};

export default reducer;
