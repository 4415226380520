import React, { useState, useCallback, useEffect } from 'react'
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { getOrientation } from 'get-orientation/browser'
import { getCroppedImg, getCroppedImgURL, getRotatedImage } from './canvasUtils'
import { styles } from './styles'

const ORIENTATION_TO_ANGLE = {
  '3': 180,
  '6': 90,
  '8': -90,
}

export const CustomCrop = ({ classes, confirmCrop, selectedImageSrc, aspectRatio}) => {
  const [imageSrc, setImageSrc] = React.useState(selectedImageSrc)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [selectedAspectRatio, setSelectedAspectRatio] = useState(1)

  const onCropComplete = useCallback(
    (croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels)
    },
    []
  )
  const toggleAspectRaio = ()=>{
    let selectedAspectRatio_ = 1 ;
    if(selectedAspectRatio == 1 ){
      selectedAspectRatio_ = 4/3;
    }else if(selectedAspectRatio == 4/3 ){
      selectedAspectRatio_ = 16/9;
    }else if(selectedAspectRatio == 16/9 ){
      selectedAspectRatio_ = 2;
    }else if(selectedAspectRatio == 2 ){
      selectedAspectRatio_ = 1;
    }
    setSelectedAspectRatio(selectedAspectRatio_)
  }
  const confirmCroppedImage = useCallback(async () => {
    try {
      
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation,
        'cropped',
      )

      const croppedImageUrl = await getCroppedImgURL(
        imageSrc,
        croppedAreaPixels,
        rotation,
        'cropped',
      )
      const finalResult = {
        imageSrc,
        croppedImage,
        croppedImageUrl,
      }
      window.URL.revokeObjectURL(imageSrc)
      confirmCrop(finalResult)
    } catch (e) {
      console.error(e)
    }
  }, [imageSrc, croppedAreaPixels, rotation])
  useEffect(()=>{ 
    if(selectedImageSrc)
      setImageSrc(selectedImageSrc)
  },[selectedImageSrc])

  useEffect(()=>{ 
    if(aspectRatio)
      setSelectedAspectRatio(aspectRatio)
  },[aspectRatio])

  const getAspectraioString = (a) => {
     switch(a){
      case 1 :  return "1:1" ;
      case 4/3 :  return "4:3" ; 
      case 16/9 :  return "16:9" ; 
      case 2 :  return "2:1" ; 
     }
  };

  return (
    <div>
      {imageSrc ? 
        <React.Fragment>
          <div className={classes.cropContainer}>
            <Cropper
              image={imageSrc}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={selectedAspectRatio}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className={classes.controls}>
            {!aspectRatio ?
            <Button
              onClick={toggleAspectRaio}
              variant="contained"
              color="primary"
              classes={{ root: classes.aspectRatioButton }}
            >
              Aspect Ratio [{getAspectraioString(selectedAspectRatio)}]
            </Button>
            : null
            }
            <div className={classes.sliderContainer}>
              <Typography
                variant="overline"
                classes={{ root: classes.sliderLabel }}
              >
                Zoom
              </Typography>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                classes={{ root: classes.slider }}
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </div>
            <Button
              onClick={confirmCroppedImage}
              variant="contained"
              color="primary"
              classes={{ root: classes.cropButton }}
            >
              Crop
            </Button>
          </div>
        </React.Fragment>
      : 
      <div className={classes.cropContainer}>
        ---
      </div>
      }
    </div>
  )
}



export const CustomCropStyled = withStyles(styles)(CustomCrop)

