import React from "react";

import CustomWizard from "../../components/custom/CustomWizard"
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import OrganizationCreate from "./WizardSteps/OrganizationCreate";
import WizardProviders from "views/Wizard/WizardProviders"
import WizardServices from "views/Wizard/WizardServices"
import WizardScheduledService from "views/Wizard/WizardScheduledService"
import FinalComponent from "views/Wizard/WizardSteps/FinalComponent"

export default function WizardView({ history }) {
	return (
		<GridContainer justifyContent="center">
			<GridItem xs={12} sm={12} md={10} lg={8}>
				<CustomWizard
					steps={[
						{ stepName: "Organization Details", stepComponent: OrganizationCreate, stepId: "organizationDetails" },
						{ stepName: "Providers Details", stepComponent: WizardProviders, stepId: "wizardProviders" },
						{ stepName: "Services Details", stepComponent: WizardServices, stepId: "wizardServices" },
						{ stepName: "Scheduled Services", stepComponent: WizardScheduledService, stepId: "WizardScheduledService" },
					]}
					finalComponent={FinalComponent}
					title="Welcome To QUEZONE"
					subtitle="Setup Your Organization"
					finishButtonClick={(e) => alert(e)}
					history={history}
				/>
			</GridItem>
		</GridContainer>
	);
}
