import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';
import DoneIcon from '@material-ui/icons/DoneAll';
import WarningIcon from '@material-ui/icons/Warning';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardText from 'components/Card/CardText';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import Button from "components/CustomButtons/Button.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import moment from 'moment-timezone';

import { fetchOrganizationsByBusinessAdminId } from "actions/organization";
import { createOrganizationWizard } from 'actions/organization.js';
import { createProvider } from 'actions/provider';
import { createService } from 'actions/service';
import { createNewEvent } from "actions/calendar"
import { generateRepeatPayload } from 'views/Calendar/helpers';
import { EVENT_REPEAT_TYPE } from 'constants/Calendar.constants';


import { roseColor } from 'assets/jss/material-dashboard-pro-react';
import { BeatLoader, HashLoader, SyncLoader } from 'react-spinners';
import { createServiceCategory } from 'actions/serviceCategories';

const FinalComponent = (props, itemRef) => {

    const { history } = props;

    const dispatch = useDispatch();

    const [isFinished, setIsFinished] = useState(false);

    /* Status : 1 == inprogress , 2 == success, 3 == failed/warning */
    const [orgApiStatus, setOrgApiStatus] = useState(0);
    const [userApiStatus, setUserApiStatus] = useState(0);
    const [serviceApiStatus, setServiceApiStatus] = useState(0);
    const [scheduleApiStatus, setScheduleApiStatus] = useState(0);

    const [errors, setErrors] = useState([])

    /* Redux Actions */
    const dispatchCreateOrganization = (payload)=>dispatch(createOrganizationWizard(payload));
    const dispatchCreateProvider = (payload)=>dispatch(createProvider(payload, null, true));
    const dispatchCreateServiceCategory = (payload)=>dispatch(createServiceCategory(payload));
    const dispatchCreateService = (payload)=>dispatch(createService(payload, null, true));
    const dispatchCreateNewEvent = (payload)=>dispatch(createNewEvent(payload,false,true));
    const dispatchOrganizationDetails = ()=>dispatch(fetchOrganizationsByBusinessAdminId());

    const organizations = useSelector(state => state.organization.organizations) ;

    const wizardSubmit = async (allStates) => {
        await initiateSetup(allStates);
    }

    useImperativeHandle(itemRef, () => ({
        wizardSubmit: wizardSubmit
    }))

    useEffect(()=>{
        /*Redirect to dashboard if organization is already created for this user */
        console.log("organizations", organizations)
        if(!orgApiStatus && organizations.length){
            //proceedToDashboard();
        }
    },[organizations])

    useEffect(()=>{
        dispatchOrganizationDetails()
    },[])

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const initiateSetup = async (allStates) => {

        await delay(500);
        const orgResp = organizations.length ?  organizations[0]  : await setupOrganization(allStates);
        await delay(500);
        const userRespArr = await setupProviders(allStates, orgResp);
        await delay(500);
        const serviceRespArr = await setupServices(allStates, orgResp);
        await delay(500);
        const scheduleRespArr = await setupSchedules(allStates, serviceRespArr, userRespArr);
        await delay(500);
        setIsFinished(true)
    }

    const setupOrganization = async (allStates) => {
        setOrgApiStatus(1);
        let orgResponse = false;
        const organizationPayload = allStates.organizationPayload;
        let responseData =  await dispatchCreateOrganization(organizationPayload);
        if(responseData?.success && responseData?.object ){
            orgResponse = responseData?.object;
            setOrgApiStatus(2);
        }else{
            if(responseData?.message){
                const message = "Error Creating Organization: "+responseData?.message;
                setErrors(prev=>[...prev, message]);
            }
            setOrgApiStatus(3);
        }
        return orgResponse
    }
    const setupProviders = async (allStates, orgResp) => {
        setUserApiStatus(1);
        const orgID = orgResp.id;
        let responseArray = [];
        const providerPayloadArray = allStates.providerPayloadArray;
        for (const payloadValues of providerPayloadArray) {
            let payload = {...payloadValues};
            payload['providerInformation']['organizationId']=orgID;
            
            console.log(" Service Payload,", payload)
            const responseData = await dispatchCreateProvider(payload);
            if(responseData?.success && responseData?.object ){
                const responseObject = responseData?.object
                responseArray.push(responseObject);
            }else{
                if(responseData?.message){
                    const message = "Error Creating User: "+payload.email+", "+responseData?.message;
                    setErrors(prev=>[...prev, message]);
                }
            }
        }
        if (providerPayloadArray.length == responseArray.length) {
            setUserApiStatus(2);
        } else {
            setUserApiStatus(3);
        }
        console.log(" Provider responseArray,", responseArray)
        return responseArray;
    }
    const addServiceCategory = async (serviceDetails) =>{
        const name = serviceDetails?.serviceCategoryName ?? "";
        const parentCategoryId = undefined;
        const iconPack = 'materialUiIcons';
        const iconName = serviceDetails?.serviceCategoryIcon ?? 'gavel';
        const category = await dispatchCreateServiceCategory({ name, parentCategoryId, iconPack, iconName });
        console.log("ServiceCategory Created :", category );
        return category?.id
    }
    const setupServices = async (allStates, orgResp) => {
        setServiceApiStatus(1);
        const orgID = orgResp.id;
        let responseArray = [];
        const servicePayloadArray = allStates.servicePayloadArray;
        for (const payloadValues of servicePayloadArray) {
            let payload = {...payloadValues};
            payload['organizationId']=orgID;
            // create service category
            const categoryId = await addServiceCategory(payload);
            if(categoryId){
                payload['serviceCategoryId']=categoryId;
            }
            console.log(" Service Payload,", payload)
            const responseData = await dispatchCreateService(payload);
            if(responseData?.success && responseData?.object ){
                const responseObject = responseData?.object
                responseArray.push(responseObject);
            }else{
                if(responseData?.message){
                    const message = "Error Creating Service: "+payload.name+", "+responseData?.message;
                    setErrors(prev=>[...prev, message]);
                }
            }
        }
        if (servicePayloadArray.length == responseArray.length) {
            setServiceApiStatus(2);
        } else {
            setServiceApiStatus(3);
        }
        console.log(" Service responseArray,", responseArray)
        return responseArray;
    }
    const setupSchedules = async (allStates, serviceRespArr, userRespArr) => {
        setScheduleApiStatus(1);
        let responseArray = [];

        const scheduledServicePayloadArray = allStates.scheduledServicePayloadArray;
        for (const payloadValues of scheduledServicePayloadArray) {
            const selectedTzOffset = moment.tz(payloadValues.timezoneId).format('Z');

            const userDetails =  userRespArr?.length ? userRespArr.find((item,index)=>payloadValues?.providerId == item.email ) : false;
            const serviceDetails = serviceRespArr?.length ? serviceRespArr.find((item,index)=>payloadValues?.serviceId == item?.name?.toLowerCase()?.trim() ) : false;
            if(!userDetails?.id || !serviceDetails?.id){
                const message = "Error Scheduling Service: Failed to get Provider or Service Details";
                setErrors(prev=>[...prev, message]);
                continue
            }

            const repeatPayload = generateRepeatPayload(payloadValues.repeat, selectedTzOffset);
            
            let payload = { ...payloadValues };
            if (payloadValues?.repeat?.type !== EVENT_REPEAT_TYPE.NEVER) {
                delete payload.repeat;
                payload = { ...payloadValues, ...repeatPayload };
            } else {
                delete payload.repeat;
            }
            payload.providerId=userDetails?.id;
            payload.serviceId=serviceDetails?.id;

            console.log("tmp Service payload,", payload)
            const responseData = await dispatchCreateNewEvent(payload);
            if(responseData?.success && responseData?.object ){
                const reponseObject = responseData?.object
                responseArray.push(reponseObject);
            }else{
                if(responseData?.message){
                    const message = "Error Scheduling Service: "+payload.name+", "+responseData?.message;
                    setErrors(prev=>[...prev, message]);
                }
            }
        }
        if (scheduledServicePayloadArray.length == responseArray.length) {
            setScheduleApiStatus(2);
        } else {
            setScheduleApiStatus(3);
        }
        console.log(" Tmp Service responseArray,", responseArray)
        return responseArray;
    }


    const proceedToDashboard = () => {
        history.push('/dashboard')
    }

    return (
        <>
            <GridContainer justifyContent="center">
                <GridItem xs={12} sm={12} md={12} lg={8}>
                    <Card>
                        <CardBody>
                            <GridContainer
                                //justifyContent="center"
                                spacing={2}
                                style={{ marginTop: 20, padding: 10, opacity: orgApiStatus ? 1 : 0.5 }}
                            >
                                <GridItem
                                    md={8}
                                    style={{ textAlign: 'center', fontWeight: 600, fontSize: 20, margin: 5, color: roseColor[0] }}
                                >
                                    Setting Up Organization
                                </GridItem>
                                <GridItem
                                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
                                >
                                    {orgApiStatus == 1 ?
                                        <BeatLoader size={10} color={roseColor[0]} />
                                        : orgApiStatus == 2 ?
                                            <DoneIcon style={{ color: "green" }} />
                                            : orgApiStatus == 3 ?
                                                <WarningIcon style={{ color: "orange" }} />
                                                : null
                                    }
                                </GridItem>
                            </GridContainer>
                            <GridContainer
                                //justifyContent="center"
                                spacing={2}
                                style={{ padding: 10, opacity: userApiStatus ? 1 : 0.5 }}
                            >
                                <GridItem
                                    md={8}
                                    style={{ textAlign: 'center', fontWeight: 600, fontSize: 20, margin: 5, color: roseColor[0] }}
                                >
                                    Setting Up User Accounts
                                </GridItem>
                                <GridItem
                                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
                                >
                                    {userApiStatus == 1 ?
                                        <BeatLoader size={10} color={roseColor[0]} />
                                        : userApiStatus == 2 ?
                                            <DoneIcon style={{ color: "green" }} />
                                            : userApiStatus == 3 ?
                                                <WarningIcon style={{ color: "orange" }} />
                                                : null
                                    }
                                </GridItem>
                            </GridContainer>
                            <GridContainer
                                //justifyContent="center"
                                spacing={2}
                                style={{ padding: 10, opacity: serviceApiStatus ? 1 : 0.5 }}
                            >
                                <GridItem
                                    md={8}
                                    style={{ textAlign: 'center', fontWeight: 600, fontSize: 20, margin: 5, color: roseColor[0] }}
                                >
                                    Setting Up Services
                                </GridItem>
                                <GridItem
                                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
                                >
                                    {serviceApiStatus == 1 ?
                                        <BeatLoader size={10} color={roseColor[0]} />
                                        : serviceApiStatus == 2 ?
                                            <DoneIcon style={{ color: "green" }} />
                                            : serviceApiStatus == 3 ?
                                                <WarningIcon style={{ color: "orange" }} />
                                                : null
                                    }
                                </GridItem>
                            </GridContainer>
                            <GridContainer
                                //justifyContent="center"
                                spacing={2}
                                style={{ padding: 10, opacity: scheduleApiStatus ? 1 : 0.5 }}
                            >
                                <GridItem
                                    md={8}
                                    style={{ textAlign: 'center', fontWeight: 600, fontSize: 20, margin: 5, color: roseColor[0] }}
                                >
                                    Scheduling Services
                                </GridItem>
                                <GridItem
                                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
                                >
                                    {scheduleApiStatus == 1 ?
                                        <BeatLoader size={10} color={roseColor[0]} />
                                        : scheduleApiStatus == 2 ?
                                            <DoneIcon style={{ color: "green" }} />
                                            : scheduleApiStatus == 3 ?
                                                <WarningIcon style={{ color: "orange" }} />
                                                : null
                                    }
                                </GridItem>
                            </GridContainer>
                            <GridContainer justifyContent="center" style={{ marginTop: 30, padding: 10 }}>
                                <Button
                                    color="rose"
                                    onClick={proceedToDashboard}
                                    disabled={!isFinished}
                                >
                                    Proceed to Dashboard
                                </Button>
                            </GridContainer>
                            { errors.map((error,index)=>
                            <SnackbarContent
                                message={error}
                                //close
                                icon={WarningIcon}
                                color="danger"
                                key={index}
                            />
                            )

                            }
                        </CardBody>
                    </Card>
                </GridItem>

            </GridContainer>

        </>

    )

}

export default forwardRef(FinalComponent);