import axios from 'axios';
import { URL } from 'config/config';

export const FETCH_PROVIDERS_OPTIONS_SUCCESS = 'FETCH_PROVIDERS_OPTIONS_SUCCESS';
export const FETCH_PROVIDERS_OPTIONS_LOADING = 'FETCH_PROVIDERS_OPTIONS_LOADING';

export const setProviderOptionsSuccess = payload => ({
  type: FETCH_PROVIDERS_OPTIONS_SUCCESS,
  payload
});

const setProviderOptionsLoading = payload => ({
  type: FETCH_PROVIDERS_OPTIONS_LOADING,
  payload
});

const sorting_function = (object_a,object_b) => {
    let string_a = object_a.label.toLowerCase()
    let string_b = object_b.label.toLowerCase()

    if (string_a > string_b) {
        return 1
    }
    if (string_a < string_b) {
        return -1
    }
    return 0
}

export const fetchProvidersByBusinessAdminId = () => dispatch => {
  dispatch(setProviderOptionsLoading(true));
  return axios.get(`${URL.FETCH_PROVIDERS_BY_BUSINESS_ADMIN_ID}/`)
    .then(res => {
      if (res && res.status === 200 && res.data.success) {
        dispatch(setProviderOptionsSuccess(res.data.objects))
      }
    }).finally(()=>{
      dispatch(setProviderOptionsLoading(false));
    })
};

export const fetchProvidersByOrgId = (orgId) => dispatch => {
  dispatch(setProviderOptionsLoading(true));
  return axios.get(`${URL.FETCH_PROVIDERS_BY_ORG_ID}/${orgId}`)
    .then(res => {
      if (res && res.status === 200 && res.data.success) {
        dispatch(setProviderOptionsSuccess(res.data.objects))
      }
    }).finally(()=>{
      dispatch(setProviderOptionsLoading(false));
    })
};

export const fetchProviderOptionsByOrgId = (orgId) => dispatch => {
  dispatch(setProviderOptionsLoading(true));
  return axios.get(`${URL.FETCH_PROVIDERS_OPTION_BY_ORG_ID}/${orgId}`)
    .then(res => {
      if (res && res.status === 200 && res.data.success) {
        dispatch(setProviderOptionsSuccess(res.data.objects))
      }
    }).finally(()=>{
      dispatch(setProviderOptionsLoading(false));
    })
};



// returns deleted providers as well for compatibility reasons with report generation
export const fetchProviderOptionsByBusinessAdminId = () => dispatch => {
   dispatch(setProviderOptionsLoading(true));
  return axios.get(`${URL.FETCH_PROVIDERS_OPTION_BY_BUSINESS_ADMIN_ID}/`)
   .then(res => {
     if (res && res.status === 200 && res.data.success) {
      dispatch(setProviderOptionsSuccess(res.data.objects.sort(sorting_function)))
      dispatch(setProviderOptionsSuccess(res.data.objects))
     }
   }).finally(()=>{
    dispatch(setProviderOptionsLoading(false));
  })
}

export const fetchProviderOptionsByBusinessAdminIdDisplay = () => dispatch => {
  dispatch(setProviderOptionsLoading(true));
  return axios.get(`${URL.FETCH_PROVIDERS_OPTION_BY_BUSINESS_ADMIN_ID_DISPLAY}/`)
    .then(res => {
      if (res && res.status === 200 && res.data.success) {
        dispatch(setProviderOptionsSuccess(res.data.objects.sort(sorting_function)))
      }
    }).finally(()=>{
      dispatch(setProviderOptionsLoading(false));
    });
}

