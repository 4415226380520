import { API_ROOT } from "config/config";

export const eUserType = {
  guest: 'GUEST',
  customer: 'CUSTOMER',
  provider: 'PROVIDER',
  business_admin: 'BUSINESS_ADMIN',
  admin: 'ADMIN',
  super_admin: 'SUPER_ADMIN',
};

export const eSurveyStatus = {
  completed: 'COMPLETED',
  inProgress: 'INPROGRESS',
  pending: 'PENDING',
  expired: 'EXPIRED',
};

export const serviceMode = {
  QUEUE: 'QUEUE',
  SCHEDULE: 'SCHEDULE',
  BOTH: 'BOTH_QUE_AND_SCHEDULE',
  CALLBACK: 'CALLBACK',
};

export const eRegisterPage = {
  eReceivedInfo: 'eReceivedInfo',
  registerTermAndCondition: 'registerTermAndCondition',
};

export const userStatus = {
  unconfirmed: 'UNCONFIRMED',
  confirmed: 'CONFIRMED',
  temporary: 'NEW_PASSWORD_REQUIRED',
  changePassword: 'FORCE_CHANGE_PASSWORD',

};

export const eventStatus = {
  unspecified: 'UNSPECIFIED',
  checkedIn: 'CHECKED_IN',
  started: 'STARTED',
  completed: 'COMPLETED',
  confirmed: 'CONFIRMED',
  cancelled: 'CANCELED',
  noShow: 'NO_SHOW',
  declined: 'DECLINED',
  failed: 'FAILED',
};

export const boardMode = {
  queue: 'QUEUE'
};

export const restApiResponseCodes = {
  success: 200,
  unauthorized: 401,
  forbidden: 403,
  notfound: 404
};

// Email Templates
export const eTemplateUrl = 'email-templates';
export const eTemplateNameMax = 250;
export const eTemplateContentMax = 150000;
export const eTemplateApi = `${API_ROOT}${eTemplateUrl}`;

export const defaultDateTimeFormat = 'DD/MM/YYYY LT';
export const selectDateFormat = 'dddd, DD MMMM YYYY';
export const timeSlotFormat = 'h:mm A';
export const shortDateFormat = 'DD MMM YYYY';
export const DATE_LABEL = 'ddd, DD MMM YYYY';
export const TIME_FORMAT = 'h:mm a';

export const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const defaultWorkingHours = weekDays.reduce((acc, value) => {
  return {
    ...acc,
    [value]: {
      startTime: '08:00',
      endTime: '18:00'
    },
  };
}, {});

export const defaultReportTime = {hour:0,minute:0};

export const NO_SLOTS_PER_ROW = 3;
export const NO_ROWS_PER_DATE = 3;

export const BREAK_TIME_OFFSET = 30; //Minutes


export const defaultWorkingHoursObj =[
  {
    "day": "Sunday",
    "startTime": {
      "hour": 8,
      "minute": 0
    },
    "endTime": {
      "hour": 18,
      "minute": 0
    }
  },
  {
    "day": "Monday",
    "startTime": {
      "hour": 8,
      "minute": 0
    },
    "endTime": {
      "hour": 18,
      "minute": 0
    }
  },
  {
    "day": "Tuesday",
    "startTime": {
      "hour": 8,
      "minute": 0
    },
    "endTime": {
      "hour": 18,
      "minute": 0
    }
  },
  {
    "day": "Wednesday",
    "startTime": {
      "hour": 8,
      "minute": 0
    },
    "endTime": {
      "hour": 18,
      "minute": 0
    }
  },
  {
    "day": "Thursday",
    "startTime": {
      "hour": 8,
      "minute": 0
    },
    "endTime": {
      "hour": 18,
      "minute": 0
    }
  },
  {
    "day": "Friday",
    "startTime": {
      "hour": 8,
      "minute": 0
    },
    "endTime": {
      "hour": 18,
      "minute": 0
    }
  },
  {
    "day": "Saturday",
    "startTime": {
      "hour": 8,
      "minute": 0
    },
    "endTime": {
      "hour": 18,
      "minute": 0
    }
  }
]
