import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { InputAdornment, Checkbox, FormControlLabel } from '@material-ui/core';
import { Email, Lock as LockOutline, Check } from '@material-ui/icons';
import BusinessIcon from '@material-ui/icons/Business';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik'
import * as Yup from 'yup'

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import registerPageStyle from 'assets/jss/material-dashboard-pro-react/views/registerPageStyle';

import VerificationPage from './VerificationPage';
import validatePassword from '../../validation/validation';
import { registerUser, facebookSignIn } from 'actions/auth';

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      cardAnimation: 'cardHidden',
      registerEmail: '',
      registerCheckbox: false,
      registerCheckboxState: '',
      openVerificationModal: false,
      loading: false,

    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ cardAnimation: '' });
    }, 200);
  }

  submit = (values) => {
    this.setState({
      registerEmail : values.email
    })
    this.props.registerUser({
      registerGivenName : values.name,
      registerEmail : values.email,
      registerPassword : values.newPassword,

    })
  }

  change = (event, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case 'checkbox':
        if (event.target.checked) {
          this.setState({ [`${stateName}State`]: 'success' });
        } else {
          this.setState({ [`${stateName}State`]: 'error' });
        }
        let isActive = event.target.checked;
        this.setState({ isActive: isActive });
        this.setState({ [stateName]: event.target.value });
        break;
      default:
        if (event.target.value) {
          this.setState({ [`${stateName}State`]: 'success' });
        } else {
          this.setState({ [`${stateName}State`]: 'error' });
        }
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }

  render() {
    const { classes, history } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={6} md={4}>
            <form>
              <Card className={classes[this.state.cardAnimation]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="rose"
                >
                  <h4 className={classes.cardTitle} style={{color: '#FFF'}}>Register</h4>
                </CardHeader>
                <CardBody>
                  <Formik
                    validationSchema={
                      Yup.object().shape({
                        name: Yup.string()
                          .required('Enter a name')
                          .matches(/^.{3,}$/, 'Must have atleast 3 characters'),
                        email: Yup.string()
                          .email('Invalid email address')
                          .required('Enter an email address'),
                        newPassword: Yup.string()
                          .required('Please enter a password')
                          .matches(/^.*[a-z].*$/, 'Must contain atleast one lowercase alphabet' )
                          .matches(/^.*[A-Z].*$/, 'Must contain atleast one uppercase alphabet')
                          .matches(/^.*[0-9].*$/, 'Must contain atleast one number')
                          .matches(/^.*[!@#$%^&*_\-+=`~].*$/, 'Must contain a special character !@#$`...')
                          .matches(/^.{8,}$/, 'Must have atleast 8 characters'),
                        confirmPassword: Yup.string()
                          .required('Please re-enter your password')
                          .oneOf([Yup.ref('newPassword'), null], "The passwords entered doesn't match"),
                      })
                    }
                    onSubmit={(values) => this.submit(values)}
                    initialValues={{
                      name:'',
                      email:'',
                      newPassword:'',
                      confirmPassword:''
                    }}
                  >
                    {
                      (formikProps) => {
                        const {
                          handleSubmit,
                          values,
                          handleChange,
                          handleBlur,
                          errors,
                          touched,
                        } = formikProps
                        return (
                        <form
                          onSubmit={handleSubmit}
                        >
                          <CustomInput
                            labelText="Business Name"
                            error={ touched.name && errors.name}
                            success={!(touched.name && errors.name)}
                            id="name"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              placeholder: 'Name',
                              type: 'text',
                              helperText: errors.name,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <BusinessIcon className={classes.inputAdornmentIcon} />
                                </InputAdornment>
                              ),
                              onChange: handleChange,
                              value: values.name,
                              onBlur:handleBlur
                            }}
                          />
                          { ( touched.name && errors.name  ) ? 
                            <div style={{ color: "red", marginBottom:5, fontSize:12 }}>{ errors.name }</div>
                            :null
                          }
                          <CustomInput
                            labelText="Email"
                            success={!(touched.email && errors.email)}
                            error={touched.email && errors.email}
                            id="email"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: 'email',
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Email className={classes.inputAdornmentIcon} />
                                </InputAdornment>
                              ),
                              onChange: handleChange,
                              value: values.email,
                              onBlur:handleBlur
                            }}
                          />
                          { ( touched.email &&  errors.email  ) ? 
                            <div style={{ color: "red", marginBottom:5, fontSize:12 }}>{ errors.email }</div>
                            :null
                          }
                          <CustomInput
                            labelText="Password"
                            success={!( touched.newPassword &&errors.newPassword)}
                            error={ touched.newPassword && errors.newPassword}
                            id="newPassword"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: 'password',
                              endAdornment: (
                                <InputAdornment position="end">
                                  <LockOutline className={classes.inputAdornmentIcon} />
                                </InputAdornment>
                              ),
                              onChange: handleChange,
                              value: values.newPassword,
                              onBlur:handleBlur
                            }}
                          />
                          {  ( touched.newPassword &&  errors.newPassword  ) ? 
                            <div style={{ color: "red", marginBottom:5, fontSize:12 }}>{ errors.newPassword  }</div>
                            :null
                          }
                          <CustomInput
                            labelText="Confirm Password"
                            success={!(touched.confirmPassword && errors.confirmPassword)}
                            error={touched.confirmPassword && errors.confirmPassword}
                            id="confirmPassword"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: 'password',
                              endAdornment: (
                                <InputAdornment position="end">
                                  <LockOutline className={classes.inputAdornmentIcon} />
                                </InputAdornment>
                              ),
                              onChange: handleChange,
                              value: values.confirmPassword,
                              onBlur:handleBlur
                            }}
                          />
                          { ( touched.confirmPassword &&  errors.confirmPassword  ) ? 
                            <div style={{ color: "red", marginBottom:5, fontSize:12 }}>{ errors.confirmPassword }</div>
                            :null
                          }
                          <FormControlLabel
                            control={
                              <Checkbox
                                tabIndex={-1}
                                onClick={event => this.change(event, 'registerCheckbox', 'checkbox')}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                  checked: classes.checked
                                }}
                              />
                            }
                            classes={{
                              label:
                                classes.label +
                                (this.state.registerCheckboxState === 'error'
                                  ? ` ${classes.labelError}`
                                  : '')
                            }}
                            label={<span>I agree to the terms and conditions</span>}
                          />
                          <VerificationPage
                            open={this.props.verify}
                            email={this.state.registerEmail}
                            history={history}
                            page="register"
                          />
                          <div
                            className={classes.center}
                          >
                            <Button
                              disabled = {!this.state.isActive}
                              type='submit'
                              color="rose"
                              simple
                              size="lg"
                              block
                              onClick={handleSubmit}
                            >
                              Submit
                            </Button>
                          </div>
                        </form>
                        )
                      }
                    }
                  </Formik>
                </CardBody>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    userDetail: state.user.userDetail,
    userLoading: state.user.userLoading,
    verify: state.user.verify
  };
};

const mapDispatchToProps = dispatch => {
  return {
    registerUser: value => dispatch(registerUser(value)),
    facebookSignIn: () => dispatch(facebookSignIn())
  };
};

export default compose(
  withStyles(registerPageStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(RegisterPage);
