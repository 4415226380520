import { tmp_service } from '../constants/TmpServices.constants';
import { cloneDeep } from 'lodash'

const initialState = {
  isLoading: false,
  list: [],

  tmp_service: [],

  editTmpServiceError: null,
  editTmpServiceLoading: false,

  delTmpServiceLoading: false,

  reportData: {
    filename: '',
    data: [],
    headers: [
      { label: 'Email', key: 'customerEmail' },
      { label: 'Full name', key: 'customerName' },
      { label: 'Phone number', key: 'customerPhone' },
      { label: 'Booking code', key: 'bookingCode' },
      { label: 'Start time', key: 'startTime' },
      { label: 'To time', key: 'toTime' },
      { label: 'Status', key: 'status' }
    ]
  },
  isReportLoading: false,
  isTriggerEmailLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case tmp_service.FETCH_TMP_SERVICES_SUCCESS:
      return {
        ...state,
        list: action.payload.sort((prev, next) => {
          return prev.slot.startTime <= next.slot.startTime ? -1 : 1;
        }),
      };
    case tmp_service.FETCH_TMP_SERVICES_LOADING:
      return { ...state, isLoading: action.payload };

    case tmp_service.DEL_TMP_SERVICE_LOADING:
      return { ...state, isLoading: true };

    case tmp_service.DEL_TMP_SERVICE_SUCCESS:
      return { ...state, list: action.payload, isLoading: false };

    case tmp_service.SET_TMP_SERVICE: {
      const itemIndex = state.list.findIndex((element) => element.id === action.payload.id)
      let modifiedArray = cloneDeep(state.list)
      if ((action.payload.slots !== null) && (action.payload.slots !== undefined))
        modifiedArray[itemIndex] = action.payload //the slots value returns none in any and all api if called quickly enough after editing(suspect creation too) a scheduled service
      else
        location.reload() // eslint-disable-line no-restricted-globals 
      modifiedArray.sort((prev, next) => prev.slot.startTime - next.slot.startTime) //subtraction gives ascending order for sorting
      return {
        ...state,
        list: modifiedArray
          /*
          state
            .list
            .map((service) => {
              return service.id === action.payload.id ? action.payload : service
            })
            .sort((prev, next) => {
              return prev.slot.startTime <= next.slot.startTime ? -1 : 1;
            })
        */
      }
    }
    case tmp_service.SET_SCHEDULE_REPORT_DATA:
      return {
        ...state,
        reportData: {
          ...state.reportData,
          filename: action.payload.providerName
            ? `Quezone schedule report ${action.payload.providerName} - ${action.payload.dateEvent}.csv`
            : '',
          data: action.payload.tmServiceReportList.map(item => ({
            customerEmail: item.customerEmail,
            customerName: item.customerName,
            bookingCode: `=""${item.bookingCode}""`,
            customerPhone: `=""${item.customerPhone}""`,
            startTime: item.startTime,
            toTime: item.toTime,
            status: item.status
          })),
        }
      };
    case tmp_service.SET_SCHEDULE_REPORT_LOADING:
      return {
        ...state,
        isReportLoading: action.payload
      };
    case tmp_service.SET_TRIGGER_EMAIL_LOADING:
      return {
        ...state,
        isTriggerEmailLoading: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
