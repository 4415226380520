import { 
    FETCH_SMS_TEMPLATE_SUCCESS,
    FETCH_SMS_TEMPLATE_LOADING,
    FETCH_SMS_TEMPLATE_ERROR,
    FETCH_SMS_EVENT_SUCCESS

} from "actions/smsTemplates";


const initialState = {
    smsEvents: [],
    loading:false,
    smsTemplates:[],
    error:null
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_SMS_TEMPLATE_SUCCESS:
        return {
          ...state,
          smsTemplates: action.payload,
        };
      case FETCH_SMS_TEMPLATE_LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case FETCH_SMS_TEMPLATE_ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case FETCH_SMS_EVENT_SUCCESS:
        return {
          ...state,
          smsEvents: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default reducer;