// ##############################
// // // ValidationForms view styles
// #############################

import { cardTitle } from "../../material-dashboard-pro-react";
import customSelectStyle from "../../material-dashboard-pro-react/customSelectStyle";
import customInputStyle from "../../material-dashboard-pro-react/components/customInputStyle";
import modalStyle from "../../material-dashboard-pro-react/modalStyle";
import customCheckboxRadioSwitch from "../../material-dashboard-pro-react/customCheckboxRadioSwitch";

const validationFormsStyle = theme => ({
  ...customCheckboxRadioSwitch, 
  ...customSelectStyle,
  ...customInputStyle,
  ...modalStyle,
  cardTitle: {
    ...cardTitle,
    color: "#FFFFFF"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  formCategory: {
    marginBottom: "0",
    color: "#999999",
    fontSize: "14px",
    padding: "10px 0 10px"
  },
  center: {
    textAlign: "center"
  },
  justifyContentCenter: {
    justifyContent: "center"
  },
  registerButton: {
    float: "right"
  },
  switchContainer: {
    color: "rgba(37, 35, 35, 0.85)",
    display: "inline-flex",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    paddingTop: "27px",
    marginRight: "0",
  },
  radioContainer: {
    color: "rgba(37, 35, 35, 0.85)",
    display: "inline-flex",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    paddingTop: "30px",
    marginRight: "0",
  },
  checkBoxContainer: {
    color: "rgba(37, 35, 35, 0.85)",
    display: "inline-flex",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    paddingTop: "25px",
    marginRight: "0",
  },
  ...modalStyle(theme),
  workingHours: {
    marginTop: 16
  },
});

export default validationFormsStyle;

