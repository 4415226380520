import moment from 'moment-timezone';
import { isEmpty, noop } from 'lodash';
import { EVENT_REPEAT_TYPE, REPEAT_END_TYPE, EVENT_LEVEL, EVENT_TYPE } from 'constants/Calendar.constants';

export const generateTmpServicePayload = (tmpService, selectedTzOffset) => {
  const {
    avgServiceTime,
    breakTimeStart,
    breakTimeEnd,
    geoLocationId,
    customLocation,
    numberOfParallelCustomer,
    notifEmail,
    isOnline,
    serviceId,
    surveyId,
    privacy,
    creatorId,
    ownerId,
    campaignId,
    corporateId,
  } = tmpService;
  return {
    avgServiceTime,
    breakTime: {
      breakStart: moment(breakTimeStart).utcOffset(selectedTzOffset, false).unix(),
      breakEnd: moment(breakTimeEnd).utcOffset(selectedTzOffset, false).unix()
    },
    notifEmail,
    isOnline,
    ownerId,
    campaignId,
    corporateId,
    creatorId,
    geoLocationId,
    customLocation,
    numberOfParallelCustomer,
    serviceId,
    surveyId: !surveyId || surveyId === 'none' ? undefined : surveyId,
    privacy
  };
};

export const generateRepeatPayload = (repeat, selectedTzOffset) => {
  let repeatPayload = {};

  if (repeat.type === EVENT_REPEAT_TYPE.DAILY) {
    repeatPayload = {
      ...repeatPayload,
      repeatType: EVENT_REPEAT_TYPE.DAILY,
      repeat: {
        repeatDaily: {
          repeatEvery: repeat.every
        }
      }
    };
  }

  if (repeat.type === EVENT_REPEAT_TYPE.WEEKLY) {
    repeatPayload = {
      ...repeatPayload,
      repeatType: EVENT_REPEAT_TYPE.WEEKLY,
      repeat: {
        repeatWeekly: {
          repeatEveryNumWeeks: repeat.every,
          repeatOn: repeat.everyDate
        }
      }
    };
  }

  if (isEmpty(repeat.repeatEnd)) {
    repeatPayload = {
      ...repeatPayload,
      repeatEndType: REPEAT_END_TYPE.NEVER
    };
  } else {
    if (repeat.repeatEnd.afterOccur) {
      repeatPayload = {
        ...repeatPayload,
        repeatEndType: REPEAT_END_TYPE.AFTER_NUM_OCCUR,
        repeatEnd: {
          afterNumOccurrences: repeat.repeatEnd.afterOccur
        }
      };
    }

    if (repeat.repeatEnd.onDate) {
      repeatPayload = {
        ...repeatPayload,
        repeatEndType: REPEAT_END_TYPE.ON_DATE,
        repeatEnd: {
          repeatEndOn: moment(repeat.repeatEnd.onDate).utcOffset(selectedTzOffset, false).unix()
        }
      };
    }
  }

  return repeatPayload;
};

export const generatePayload = (addEventData, providers) => {
  const {
    id,
    providerId,
    startTime,
    endTime,
    eventType,
    description,
    repeat,
    tmpService,
    location,
    serviceId,
    customerEmail,
    customerFirstName,
    customerLastName,
    customerMobilePhone,
    timezoneId
  } = addEventData
  const selectedTzOffset = moment.tz(timezoneId).format('Z');

  let payload = {
    id,
    description,
    providerId,
    slot: {
      startTime: moment(startTime).utcOffset(selectedTzOffset, false).unix(),
      endTime: moment(endTime).utcOffset(selectedTzOffset, false).unix()
    },
    type: eventType,
    timezoneId
  };

  if (eventType === EVENT_TYPE.TMP_SERVICE) {
    payload = { ...payload, ...generateTmpServicePayload(tmpService, selectedTzOffset) };
  }

  if (repeat.type !== EVENT_REPEAT_TYPE.NEVER) {
    payload = { ...payload, ...generateRepeatPayload(repeat, selectedTzOffset) };
  }

  if (eventType === EVENT_TYPE.CUSTOMER_APPOINTMENT) {
    payload = {
      ...payload,
      location,
      serviceId,
      customerEmail,
      customerFirstName,
      customerLastName,
      customerMobilePhone,
    }
  }

  return payload;
};

const createOrgNewEvent = (addEventData, providers, createNewEventAction) => {
  const fetchMap = providers.map(provider => {
    const payload = generatePayload(
      { ...addEventData, providerId: provider.id },
      providers
    );
    return createNewEventAction(payload);
  });

  Promise.all(fetchMap);
};

export const createNewEventHelper = (
  { addEventData, eventLevel },
  providers,
  createNewEventAction, cb = noop
) => {
  if (eventLevel === EVENT_LEVEL.BUSINESS) {
    createOrgNewEvent(addEventData, providers, createNewEventAction);
  } else {
    createNewEventAction(generatePayload(addEventData, providers), cb);
  }
};
