import { SET_ACTIVITY_LOGS, SET_ACTIVITY_LOGS_LOADING } from "actions/activityLogs";


const initialState = {
    activityLogs: [],
    activityLogsLoading: false,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACTIVITY_LOGS:
            return {
                ...state,
                activityLogs: action.payload,
            };
        case SET_ACTIVITY_LOGS_LOADING:
            return {
                ...state,
                activityLogsLoading: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;