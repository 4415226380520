import { FETCH_PROVIDERS_OPTIONS_SUCCESS, FETCH_PROVIDERS_OPTIONS_LOADING } from "actions/providerOptions";

const initialState = {
  isFetchProviderOptionsSuccess: false,
  providerOptions: [],
  isLoading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROVIDERS_OPTIONS_SUCCESS:
      return {
        ...state,
        providerOptions: action.payload,
        isFetchProviderOptionsSuccess: true
      };
    case FETCH_PROVIDERS_OPTIONS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
