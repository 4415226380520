import { pictureUpload } from 'constants/PictureUpload.constants'
import { API_MEDIA_URL } from 'config/config'

export const uploadPictureFailure = error => {
  return {
    type: pictureUpload.PICTURE_UPLOAD_FAILURE,
    payload: { error }
  }
}

export const uploadPicture = formData => {
  return async dispatch => {
    try {
      const result = await fetch(
        API_MEDIA_URL,
        {
          method: 'POST',
          body: formData
        }
      )
      const json = await result.json()
      return json
    }
    catch(error) {
      dispatch(uploadPictureFailure(error))
    }
  }
}

