import axios from 'axios';
import { URL } from 'config/config';
import { tmp_service } from 'constants/TmpServices.constants';
import { handleRequest } from 'utils/apiHelpers';
import { FETCH_SLOTS_BY_TMP_SERVICE_LOADING, setBookingSlots } from './calendar';
import { showAlert } from './alert';
import { noop } from 'lodash';
import { delay } from 'commonFunctions'

const setTmpServicesLoading = payload => ({
  type: tmp_service.FETCH_TMP_SERVICES_LOADING,
  payload
});

const clearTmpsServiceAction = payload => ({
  type: tmp_service.CLEAR_TMP_SERVICE,
})

export const setTmpServices = payload => ({
  type: tmp_service.FETCH_TMP_SERVICES_SUCCESS,
  payload
});

export const setTmpService = payload => ({
  type: tmp_service.SET_TMP_SERVICE,
  payload
})

export const deleteTmpService = eventId => {
  return (dispatch, getState) => {
    dispatch({ type: tmp_service.DEL_TMP_SERVICE_LOADING });
    axios.delete(`${URL.NEW_TMP_SERVICE}/${eventId}`)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: tmp_service.DEL_TMP_SERVICE_SUCCESS,
            payload: getState().tmpServices.list.filter(tmpService => tmpService.id !== eventId)
          });
        }
      });
  };
};

export const fetchTmpServicesByAdminId = (status = 'ALL') => dispatch => {
  dispatch(setTmpServicesLoading(true));
  axios.get(`${URL.FIND_TMP_SERVICES_BY_BUSINESS_ID}/`, {
      params: {
        status
      }
    })
    .then(resp => {
      if (resp && resp.status === 200 && resp.data.success) {
        dispatch(setTmpServices(resp.data.objects || []));
      }
    })
    .finally(() => {
      dispatch(setTmpServicesLoading(false));
    });
}

export const editTmpService = (payload, cb=noop) => async (dispatch) => {
    dispatch(setTmpServicesLoading(true));
    const resp = await axios.put(URL.NEW_TMP_SERVICE, payload)
      if (resp && resp.status === 200 && resp.data.success) {
        await delay(2000)
        const response = await axios.get(`${URL.FIND_TMP_SERVICE_DETAIL_BY_TMP_SERVICE_ID}/${resp.data.object.id}`)
        dispatch(setTmpService(response.data.object))
          /*
        dispatch({
          type: tmpServiceDetail.FETCH_TMP_SERVICE_DETAIL_LOADING
        })
        try {
          dispatch({
            type: tmpServiceDetail.FETCH_TMP_SERVICE_DETAIL_SUCCESS,
            payload: data.object
          })
        }
        catch(error) {
          dispatch({
            type: tmpServiceDetail.FETCH_TMP_SERVICE_DETAIL_FAILURE,
            payload: error
          })

        }
        */
      }
    cb(resp)
    dispatch(setTmpServicesLoading(false));
}

export const setScheduleReportData = payload => ({
  type: tmp_service.SET_SCHEDULE_REPORT_DATA,
  payload
});

const setScheduleReportLoading = payload => ({
  type: tmp_service.SET_SCHEDULE_REPORT_LOADING,
  payload
});

const setTriggerEmailLoading = payload => ({
  type: tmp_service.SET_TRIGGER_EMAIL_LOADING,
  payload
});

export const getScheduleReport = tmpServiceId => dispatch => {
  dispatch(setScheduleReportLoading(true));
  axios.post(URL.SCHEDULE_REPORT, { tmpServiceId })
    .then(resp => {
      if (resp && resp.data.success) {
        dispatch(setScheduleReportData(resp.data.object));
      }
    })
    .finally(() => {
      dispatch(setScheduleReportLoading(false));
    });
}

export const fetchTmpServicesByProviderId = (providerId, status) => dispatch => {
  dispatch(setTmpServicesLoading(true));
  axios.get(`${URL.FIND_TMP_SERVICES_BY_PROVIDER_ID}/${providerId}`, {
      params: {
        status
      }
    })
    .then(resp => {
      if (resp && resp.status === 200 && resp.data.success) {
        dispatch(setTmpServices(resp.data.objects || []));
      }
    })
    .finally(() => {
      dispatch(setTmpServicesLoading(false));
    });
}

export const fetchTmpServicesByServiceId = (serviceId, cb = false) => dispatch => {
  dispatch(setTmpServicesLoading(true));
  axios.get(`${URL.FIND_TMP_SERVICES_BY_SERVICE_ID}/${serviceId}`)
    .then(resp => {
      if (resp && resp.status === 200 && resp.data.success) {
        dispatch(setTmpServices(resp.data.objects || []));
        cb('');
      }
    })
    .finally(() => {
      dispatch(setTmpServicesLoading(false));
    });
}

export const clearTempServices = () => dispatch =>{
  dispatch(clearTmpsServiceAction({}));
}

export const disableSlot = slotId => async (dispatch, getState) => {
  dispatch({ type: FETCH_SLOTS_BY_TMP_SERVICE_LOADING, payload: true });

  const [result] = await handleRequest(axios.put, [URL.DISABLE_BOOKING_SLOTS, { slotId, status: true }]);
  if (result && result.success) {
    const { manageCalendar } = getState();
    const newBookingSlots = manageCalendar.bookingSlots.map(slot => ({
      ...slot,
      disable: slot.id === slotId ? true : slot.disable
    }));

    dispatch(showAlert('success', 'The slot has been disabled!'));
    dispatch(setBookingSlots({ bookingSlots: newBookingSlots, bookingEventId: '' }));
  }

  dispatch({ type: FETCH_SLOTS_BY_TMP_SERVICE_LOADING, payload: false });
}
export const enableSlot = slotId => async (dispatch, getState) => {
  dispatch({ type: FETCH_SLOTS_BY_TMP_SERVICE_LOADING, payload: true });

  const [result] = await handleRequest(axios.put, [URL.DISABLE_BOOKING_SLOTS, { slotId, status: false }]);
  if (result && result.success) {
    const { manageCalendar } = getState();
    const newBookingSlots = manageCalendar.bookingSlots.map(slot => ({
      ...slot,
      disable: slot.id === slotId ? false : slot.disable
    }));

    dispatch(showAlert('success', 'The slot has been enabled!'));
    dispatch(setBookingSlots({ bookingSlots: newBookingSlots, bookingEventId: '' }));
  }

  dispatch({ type: FETCH_SLOTS_BY_TMP_SERVICE_LOADING, payload: false });
}

export const triggerEmailApi = (tmpServiceId, cb = noop) => dispatch => {
  dispatch(setTriggerEmailLoading(true));
  axios.post(`${URL.TRIGGER_EMAILS}/${tmpServiceId}`)
    .then(resp => {
      if (resp && resp.data.success) {
        cb();
      }else{
        dispatch(showAlert('error', resp.data.message));
      }
    })
    .finally(() => {
      dispatch(setTriggerEmailLoading(false));
    });
}
