
export const auth = {
  AUTH_REMOVE_TOKEN: 'AUTH_REMOVE_TOKEN',
  AUTH_SET_TOKEN: 'AUTH_SET_TOKEN',

  REGISTER_USER: 'REGISTER_USER',
  REGISTER_USER_SUCCESS: 'REGISTER_USER_SUCCESS',
  REGISTER_USER_FAILURE: 'REGISTER_USER_FAILURE',

  RESET_PASSWORD_LOADING: 'RESET_PASSWORD_LOADING',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

  OTP_REQUEST_SENT: 'OTP_REQUEST_SENT',

  FORCE_RESET_PASSWORD_LOADING: 'FORCE_RESET_PASSWORD_LOADING',
  FORCE_RESET_PASSWORD_SUCCESS: 'FORCE_RESET_PASSWORD_SUCCESS',
  FORCE_RESET_PASSWORD_FAILURE: 'FORCE_RESET_PASSWORD_FAILURE',


  CHANGE_PASSWORD_LOADING: 'CHANGE_PASSWORD_LOADING',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

  VERIFY_USER: 'VERIFY_USER',
  VERIFY_USER_SUCCESS: 'VERIFY_USER_SUCCESS',
  VERIFY_USER_FAILURE: 'VERIFY_USER_FAILURE',


  EDIT_USER_LOADING: 'EDIT_USER_LOADING',
  EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
  EDIT_USER_FAILURE: 'EDIT_USER_FAILURE',

  FETCH_USER_LOADING: 'FETCH_USER_LOADING',
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  FETCH_USER_FAILURE: 'FETCH_USER_FAILURE',

  STORE_EMAIL: 'STORE_EMAIL',

  LOGIN_USER: 'login_user',
  CHECK_AUTH: 'check_auth',
  RESET_PASSWORD: 'reset_password',
  CHANGE_PASSWORD: 'change_password',
  FORCE_CHANGE_PASSWORD: 'force_change_password',
  VERIFY_RESEND_USER: 'verify_resend_user',
  FETCH_USERTYPE_LIST: 'fetch_usertype_list',
  FETCH_USER_BY_USERID: 'fetch_user_by_userid'
};
