export const organization = {
  CREATE_ORGANIZATION_LOADING: 'CREATE_ORGANIZATION_LOADING',
  CREATE_ORGANIZATION_SUCCESS: 'CREATE_ORGANIZATION_SUCCESS',
  CREATE_ORGANIZATION_FAILURE: 'CREATE_ORGANIZATION_FAILURE',

  EDIT_ORGANIZATION_LOADING: 'EDIT_ORGANIZATION_LOADING',
  EDIT_ORGANIZATION_SUCCESS: 'EDIT_ORGANIZATION_SUCCESS',
  EDIT_ORGANIZATION_FAILURE: 'EDIT_ORGANIZATION_FAILURE',

  DEL_ORGANIZATION_LOADING: 'DEL_ORGANIZATION_LOADING',
  DEL_ORGANIZATION_SUCCESS: 'DEL_ORGANIZATION_SUCCESS',
  DEL_ORGANIZATION_FAILURE: 'DEL_ORGANIZATION_FAILURE',

  FETCH_ORGANIZATION_LOADING: 'FETCH_ORGANIZATION_LOADING',
  FETCH_ORGANIZATION_SUCCESS: 'FETCH_ORGANIZATION_SUCCESS',
  FETCH_ORGANIZATION_FAILURE: 'FETCH_ORGANIZATION_FAILURE',

  FETCH_ORGANIZATIONS_LOADING: 'FETCH_ORGANIZATIONS_LOADING',
  FETCH_ORGANIZATIONS_SUCCESS: 'FETCH_ORGANIZATIONS_SUCCESS',
  FETCH_ORGANIZATIONS_FAILURE: 'FETCH_ORGANIZATIONS_FAILURE',

  SET_ZOOM_CREDENTIALS : "SET_ZOOM_CREDENTIALS",
  SET_ORG_PAYMENT_METHODS : "SET_ORG_PAYMENT_METHODS",
  SET_ORG_PAYMENT_METHODS_OPTIONS : "SET_ORG_PAYMENT_METHODS_OPTIONS",
};
