import Dashboard from 'layouts/Dashboard'
import TvDisplay from 'layouts/TvDisplay'
import welcomeWizard from 'layouts/welcomeWizard'
import Auth from 'layouts/Auth'

const indexRoutes = [
  { path: '/login', name: 'Auth', component: Auth },
  { path: '/register', name: 'Auth', component: Auth },
  { path: '/display', name: 'Display', component: TvDisplay },
  { path: '/welcomeWizard', name: 'welcomeWizard', component: welcomeWizard },
  { path: '/', name: 'Home', component: Dashboard },
]

// the order of the items is important

export default indexRoutes;
