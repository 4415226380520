import React, { createRef, Suspense } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import withStyles from '@material-ui/core/styles/withStyles';
import { profileRouteComponent, tvDisplayRoutes, welcomeWizardRoutes} from 'routes/dashboard.js';
import appStyle from 'assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx';
import withAuth from "hoc/withAuth";
import { refreshTokens, fetchUser } from 'actions/auth.js';
import axios from 'axios';
import { userStatus } from 'constants.js';
import classNames from 'classnames';

let ps;
class welcomeWizard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.mainPanelRef = createRef();
    this.state = {
      mobileOpen: false,
      miniActive: false
    };
  }

  async componentDidMount() {
    const { history, userDetail } = this.props;
    const userSub = userDetail.id || localStorage.getItem('userSub');

    if (userDetail.userStatus !== userStatus.changePassword) {
      await this.props.refreshTokens();
    }

    if (userSub) {
      this.props.fetchUser(userSub, history);
    }

    if (this.mainPanelRef.current && navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.mainPanelRef.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      //document.body.style.overflow = 'auto';
    }
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.mainPanelRef.current.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  componentWillUnmount() {
    if (ps && navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }

  render() {
    const { classes, ...rest } = this.props;

    return (
      <div>
        <div className={classNames(classes.wrapper)} ref="wrapper">
          <div className={classes.fullPage}>
              <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                  <Route
                    path={profileRouteComponent.path}
                    component={withAuth(profileRouteComponent.component, rest.userDetail)}
                    key={profileRouteComponent.path}
                  />
                  {!!axios.defaults.headers.common['Authorization'] &&
                    [
                      ...welcomeWizardRoutes,
                    ].map((prop) => {
                      if (prop.redirect) return <Redirect from={prop.path} to={prop.pathTo} key={prop.path} />;
                      return <Route path={prop.path} component={withAuth(prop.component, rest.userDetail)} key={prop.path} />;
                    })}
                </Switch>
              </Suspense>
          </div>
        </div>
      </div>
    );


  }
}

welcomeWizard.propTypes = {
  classes: PropTypes.object.isRequired,
  userDetail: PropTypes.object.isRequired,
  refreshTokens: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  userDetail: state.user.userDetail,
});

export default withStyles(appStyle)(connect(mapStateToProps, { refreshTokens, fetchUser })(welcomeWizard));

