import { service_category } from '../constants/ServiceCategory.constants';

const initialState = {
  isLoading: false,
  list: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case service_category.FETCH_SERVICE_CATEGORIES_SUCCESS:
      return {
        ...state,
        list: action.payload.sort((a,b) => a.position - b.position)
      };
    case service_category.FETCH_SERVICE_CATEGORIES_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

export default reducer;
