export const customer_service = {
  VERIFY_BOOKING_CODE_LOADING: 'VERIFY_BOOKING_CODE_LOADING',
  VERIFY_BOOKING_CODE_SUCCESS: 'VERIFY_BOOKING_CODE_SUCCESS',
  VERIFY_BOOKING_CODE_FAILURE: 'VERIFY_BOOKING_CODE_FAILURE',

  VERIFY_MOBILE_DATA_LOADING: 'VERIFY_MOBILE_DATA_LOADING',
  VERIFY_MOBILE_DATA_SUCCESS: 'VERIFY_MOBILE_DATA_SUCCESS',
  VERIFY_MOBILE_DATA_FAILURE: 'VERIFY_MOBILE_DATA_FAILURE',

  UPDATE_STATUS_SUCCESS: 'UPDATE_STATUS_SUCCESS',

  FETCH_FLOW_BOARD_LOADING: 'FETCH_FLOW_BOARD_LOADING',
  FETCH_FLOW_BOARD_SUCCESS: 'FETCH_FLOW_BOARD_SUCCESS',
  
  BLACKLISTED_CUSTOMERS : 'BLACKLISTED_CUSTOMERS',
  BLACKLISTED_CUSTOMERS_LOADING : 'BLACKLISTED_CUSTOMERS_LOADING'

};
