import React from 'react';
import ServiceCreate from "./WizardSteps/ServiceCreate";
import Button from 'components/CustomButtons/Button';
import WizardAccordion from 'components/Accordion/WizardAccordion';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

class WizardServices extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			serviceIndexes: [1],
			error: ''
		}
		this.serviceComponentInstances = [];
		this.serviceResponses = [];
	}

	removeService(serviceIndex) {
		const indexesArray = this.state.serviceIndexes;
		this.serviceComponentInstances = this.serviceComponentInstances.filter((arr, index) => index !== serviceIndex);
		let newIndexArray = indexesArray.filter(arr => arr !== serviceIndex);
		this.setState({ serviceIndexes: newIndexArray });
		console.log("this.serviceComponentInstances >>", this.serviceComponentInstances)
	}

	addService() {
		const indexesArray = this.state.serviceIndexes;
		const len = indexesArray.length;
		let nextIndex = 1;
		if (len > 0) {
			nextIndex = indexesArray[len - 1] + 1;
		}
		indexesArray.push(nextIndex);
		this.setState({ serviceIndexes: indexesArray });
		console.log("this.serviceComponentInstances >>", this.serviceComponentInstances)
	}


	wizardSubmit = async (allStates) => {
		console.log("Services wizard submit");
		this.setState({ error: "" })
		let servicePayloadArray = []
		for (const serviceComponentInstance of this.serviceComponentInstances) {
			if (serviceComponentInstance?.wizardSubmit) {
				const payload = await serviceComponentInstance.wizardSubmit(allStates)
				if (payload) {
					const name = payload.name.toLowerCase().trim()
					const duplicateEmailEntry = servicePayloadArray.filter(e => e?.name?.toLowerCase()?.trim() == name)
					if (duplicateEmailEntry?.length) {
						this.setState({ error: "Duplicate Service Name Entry. Cannot add multiple Service with same Name" })
						return false;
					}
					servicePayloadArray.push(payload);


				} else {
					return false
				}
			}
		}
		let updatedStates = {
			...allStates,
			servicePayloadArray: servicePayloadArray
		}
		return updatedStates;
	}

	render() {
		this.serviceComponentInstances = [];
		const collapsesValue = this.state.serviceIndexes.map((serviceIndex, index) => {
			return {
				title: `Service ${serviceIndex}`,
				content: (
					<GridContainer style={{ border: "1px solid rgb(226, 220, 220)" }}>
						<GridItem md={12}>
							<ServiceCreate isWizard={true} ref={(node) => {
								if (node != null) {
									this.serviceComponentInstances[serviceIndex] = node;
								}
							}} />
							{serviceIndex > 1 && ((index + 1) == this.state.serviceIndexes.length) && <Button size="sm" color="rose" onClick={() => { this.removeService(serviceIndex) }}>
								Remove Service
							</Button>}
						</GridItem>
					</GridContainer>
				)
			}
		});
		return (
			<div>
				<WizardAccordion
					collapses={collapsesValue}
				/>
				<GridContainer justifyContent={"flex-start"}>
					<GridItem>
						{<Button size="sm" color="rose" onClick={() => { this.addService() }}>
							Add Service
						</Button>}
					</GridItem>
				</GridContainer>
				<div style={{ textAlign: 'center', color: 'red' }}>{this.state.error}</div>
			</div>
		);
	}
}
export default WizardServices;